import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { QuizRunnerService } from '../quiz-runner.service';
import { Observable, Subject, Subscription } from 'rxjs';
import {
    GameUserInfo,
    QuizMoveResponseType,
    QuizQuestion, QuizQuestionAnswerType, QuizQuestionStatus, QuizScoreData
} from '../../../../proto/generated/gambo_pb';
import { AppState } from '../../../app/app.state';
import { select, Store } from '@ngrx/store';
import {
    firstAnsweredPlayersSelector,
    gameFinishedSelector,
    playerAnswerSelector,
    playerNumberSelector,
    playersListSelector,
    questionFileSrcSelector,
    questionSelector, questionStatusSelector, quizNameSelector, quizTotalQuestionsSelector, remainingTimeSelector,
    scoreboardListSelector
} from '../store/quiz-runner.selectors';
import { takeUntil } from 'rxjs/operators';
import { getDownloadURL, getMetadata, getStorage, ref } from '@angular/fire/storage';
import { getAuth } from 'firebase/auth';
import { QuizRunnerSocket } from '../quiz-runner.socket';
import { Router } from '@angular/router';
import { Utils } from '../../../libs/utils';

@Component({
    selector: 'app-quiz-runner',
    templateUrl: './quiz-runner.component.html',
    styleUrls: ['./quiz-runner.component.scss'],
    providers: [QuizRunnerSocket]
})
export class QuizRunnerComponent implements OnInit, AfterViewInit, OnDestroy {

    private unsubscribeSubject: Subject<void> = new Subject();

    // readonly QuizQuestionAnswerType = QuizQuestionAnswerType;
    readonly QuizMoveResponseType = QuizMoveResponseType;
    readonly QuizQuestionStatus = QuizQuestionStatus;
    readonly QuizQuestionAnswerType = QuizQuestionAnswerType;

    public quizName: string;
    public quizTotalQuestionsNumber: number;
    public quizQuestion: QuizQuestion;

    public playerNumber: number;
    public players: Array<GameUserInfo>;
    public moveState: number;
    public playerAnswer: string = null;
    public correctAnswer: string = null;
    public firstAnsweredPlayer: GameUserInfo;

    // public quizCurrentState: QuizCurrentStateMessageResponse;
    // public quizMove: QuizMoveMessageResponse;

    public remainingTime = 0;
    public scoreForQuestion = null;
    public playerScore: QuizScoreData;

    public questionFileSrc: string;
    public questionStatus: number;
    public gameFinished: boolean;
    public showResults = false;

    constructor(
        public quizRunnerService: QuizRunnerService,
        public quizRunnerSocket: QuizRunnerSocket,
        private store: Store<AppState>,
        private router: Router,
        private elementRef: ElementRef
    ) { }

    ngOnInit() {
        /*if (Utils.isMobile()) {
        window.screen.orientation.lock('portrait').then(() => console.log('YES'));
        }*/

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(quizNameSelector)
        ).subscribe(quizName => {
            this.quizName = quizName;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(quizTotalQuestionsSelector)
        ).subscribe(quizTotalQuestionsNumber => {
            this.quizTotalQuestionsNumber = quizTotalQuestionsNumber;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(questionSelector)
        ).subscribe(question => {
            this.quizQuestion = question;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(playersListSelector)
        ).subscribe(playersList => {
            this.players = playersList;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(playerNumberSelector)
        ).subscribe(playerNumber => {
            this.playerNumber = playerNumber;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(playerAnswerSelector)
        ).subscribe(playerAnswer => {
            this.playerAnswer = playerAnswer;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(questionStatusSelector)
        ).subscribe(questionStatus => {
            this.questionStatus = questionStatus;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(gameFinishedSelector)
        ).subscribe(gameFinished => {
            this.gameFinished = gameFinished;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(scoreboardListSelector)
        ).subscribe(scoreboardList => {
            this.playerScore = scoreboardList.find(score => score.getPlayernumber() === this.playerNumber);
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(firstAnsweredPlayersSelector)
        ).subscribe(firstAnsweredPlayer => {
            console.log(firstAnsweredPlayer);
            this.firstAnsweredPlayer = firstAnsweredPlayer;
        });
    }

    ngAfterViewInit() {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#e0e0e0';
    }

    openMenu() {
        this.router.navigate(['quiz-list']);
    }

    public questionNumber() {
        return this.quizQuestion ? this.quizQuestion.getNumber() + 1 : null;
    }

    hostVerificationClickHandler(accept: boolean) {
        if (!this.quizRunnerSocket.isHost) {
            return;
        }
        this.quizRunnerSocket.hostVerificationRequest(this.quizQuestion.getNumber(), accept);
    }

    ngOnDestroy() {
        console.log('DEBUG DESTROY QUIZ RUNNER');
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }

    /*public downloadMediaFile(fileUrl: string): Promise<string> {
        const storage = getStorage();
        const fileRef = ref(storage, fileUrl);
        return getDownloadURL(fileRef);
    }*/
}
