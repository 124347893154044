<div *ngIf="questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && quizRunnerSocket.isHost"
     class="scoreboard neumorphic neumorphic-pressed">

    <img src="assets/images/Pedestal.png"
         alt="Score pedestal">
    <div *ngIf="scoreBoard[0]"
         class="pedestal first-place">
        <img [src]="findPlayerByNumber(scoreBoard[0].getPlayernumber()).getAvatarurl()"
             class="avatar-image">
        <div class="nickname">{{ findPlayerByNumber(scoreBoard[0].getPlayernumber()).getNickname() }}</div>
    </div>
    <div *ngIf="scoreBoard[0]"
         class="pedestal first-score">
        <div class="player-score">{{ scoreBoard[0].getTotalscore() }}</div>
    </div>
    <div *ngIf="scoreBoard[1]"
         class="pedestal second-place">
        <img [src]="findPlayerByNumber(scoreBoard[1].getPlayernumber()).getAvatarurl()"
             class="avatar-image">
        <div class="nickname">{{ findPlayerByNumber(scoreBoard[1].getPlayernumber()).getNickname() }}</div>
    </div>
    <div *ngIf="scoreBoard[1]"
         class="pedestal second-score">
        <div class="player-score">{{ scoreBoard[1].getTotalscore() }}</div>
    </div>
    <div *ngIf="scoreBoard[2]"
         class="pedestal third-place">
        <img [src]="findPlayerByNumber(scoreBoard[2].getPlayernumber()).getAvatarurl()"
             class="avatar-image">
        <div class="nickname">{{ findPlayerByNumber(scoreBoard[2].getPlayernumber()).getNickname() }}</div>
    </div>
    <div *ngIf="scoreBoard[2]"
         class="pedestal third-score">
        <div class="player-score">{{ scoreBoard[2].getTotalscore() }}</div>
    </div>

    <div *ngFor="let scoreData of scoreBoard"
         class="scoreboard-row">
        <div class="player-information">
            <img [src]="findPlayerByNumber(scoreData.getPlayernumber()).getAvatarurl()"
                 class="avatar-image">
            <div>{{ findPlayerByNumber(scoreData.getPlayernumber()).getNickname() }}</div>
        </div>
        <div class="player-score">{{ scoreData.getTotalscore() }}</div>
    </div>
</div>

