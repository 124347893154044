import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
    selector: 'app-ai-quiz',
    templateUrl: './ai-quiz.component.html',
    styleUrls: ['./ai-quiz.component.scss']
})
export class AiQuizComponent {

    public quizTopic: string;
    public topics = new Array<string>();
    // public topics = ['Science', 'Math', 'Songs'];
    public selectedTopic = '';
    public customTopic = '';

    public receipt = '';

    constructor(
        private httpClient: HttpClient,
        private router: Router
    ) {
        this.topics = ['Общая'];
        this.quizTopic = 'Общая';
        /*this.httpClient.get<{ topics: Array<string> }>(
            environment.URLPrefix + '/quiz/topics',
            {}
        ).subscribe(result => {
            if (result.topics) {
                this.topics = result.topics;
                this.quizTopic = result.topics[0];
            }
        });*/
        this.receipt = JSON.stringify({
            EmailCompany: 'info@ai-partygames.com',
            Taxation: 'usn_income',
            FfdVersion: '1.2',
            Items: [
                {
                    Name: 'Премиум подписка',
                    Price: 100 + '00',
                    Quantity: 1.00,
                    Amount: 100 + '00',
                    PaymentMethod: 'full_prepayment',
                    PaymentObject: 'service',
                    Tax: 'none',
                    MeasurementUnit: 'месяц'
                }
            ]
        });
    }

    changeTopicHandler(selectedTopic: string) {
        this.selectedTopic = selectedTopic;
        if (selectedTopic !== 'custom') {
            this.quizTopic = selectedTopic;
        } else {
            this.quizTopic = this.customTopic;
        }
    }

    changeCustomTopicHandler(eventTarget: EventTarget) {
        this.customTopic = (eventTarget as HTMLInputElement).value;
        this.quizTopic = this.customTopic;
    }

    createGame() {
        localStorage.setItem('game', 'quiz');
        localStorage.setItem('quizId', 'deadbeef-dead-beef-dead-beeeeeeeeeef');
        localStorage.setItem('quizType', 'AI_TEXT'); // AI_TEXT | SONG | EMPTY
        localStorage.setItem('quizTopic', this.quizTopic);
        localStorage.removeItem('roomId');
        this.router.navigate(
            ['/room-manager'],
            {queryParams: {action: 'run'}}
        );
    }
}
