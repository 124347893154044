import { Component } from '@angular/core';
import { QuizQuestionStatus } from '../../../proto/generated/gambo_pb';
import { Router } from '@angular/router';
import { TopicSelectorDialogComponent } from './topic-selector-dialog/topic-selector-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-ai-partygames',
    templateUrl: './ai-partygames.component.html',
    styleUrls: ['./ai-partygames.component.scss']
})
export class AiPartygamesComponent {

    protected readonly QuizQuestionStatus = QuizQuestionStatus;

    constructor(
        private router: Router,
        private matDialog: MatDialog
    ) {}

    startGame() {
        /*this.matDialog.open(TopicSelectorDialogComponent, {
            width: '400px'
        }).afterClosed().subscribe(result => {
            if (result && result !== '') {
                localStorage.setItem('game', 'quiz');
                localStorage.setItem('quizId', 'deadbeef-dead-beef-dead-beeeeeeeeeef');
                localStorage.setItem('quizType', 'AI_TEXT'); // AI_TEXT | SONG
                localStorage.setItem('quizTopic', result);
                localStorage.removeItem('roomId');
                this.router.navigate(
                    ['/room-manager'],
                    {queryParams: {action: 'run'}}
                );
            }
        });*/
        this.router.navigate(['ai-quiz']);
        /*localStorage.setItem('game', 'quiz');
        localStorage.setItem('quizId', 'deadbeef-dead-beef-dead-beeeeeeeeeef');
        localStorage.removeItem('roomId');
        this.router.navigate(
            ['/room-manager'],
            {
                queryParams: {
                    action: 'run',
                    type: 'ai-text', // ai-text | song
                    topic: 'theme'
                }
            }
        );*/
    }
}
