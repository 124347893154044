import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    exports: [
        BrowserAnimationsModule,
        MatButtonModule,
        FormsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatCardModule,
        MatSelectModule,
        MatSnackBarModule,
        MatStepperModule,
        MatButtonToggleModule,
        MatSliderModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        MatBadgeModule,
        OverlayModule,

        /*
         MatDividerModule,
         MatTableModule,
         MatSortModule,
         MatProgressSpinnerModule,
         MatSelectModule,
         MatDatepickerModule,
         MatNativeDateModule,
         MatExpansionModule,
         DragDropModule,
         MatTabsModule,
         MatRadioModule,
         MatGridListModule,
         MatBottomSheetModule,
         ScrollingModule,
         MatToolbarModule,
         MatMenuModule,
         PaginatorI18nModule,
         MatSidenavModule,
         MatAutocompleteModule
        * */
        MatMenuModule
    ]
})
export class MaterialAllInOneModule { }
