import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

export interface QuizConstructorErrors {
    getQuizError: string;
}

export interface SharedQuizDescriptionInfo {
    name: string;
    description: string;
    ownerId: string;
}

export interface QuizDescriptionInfo {
    name: string;
    description: string;
    public: boolean;
}

export interface QuizInfo extends QuizDescriptionInfo {
    id: string;
    owner: string;
    createTime: number;
    lastUpdateTime: number;
    status: QuizStatus;
    rejectionReason?: string;
    shareLink: string;
    shareLinkExpirationTime: number;
    questions: Array<QuizQuestionInfo>;
}

export interface RoomInfo {
    roomId: number;
    roomName: string;
    gameName: string;
    gameDisplayName: string;
    logoUrl: string;
    host: boolean;
    started: boolean;
    finished: boolean;
    createTime: number;
    startTime: number;
    finishTime: number;
}

export interface UserRooms {
    createdRooms: Array<RoomInfo>;
    startedRooms: Array<RoomInfo>;
    finishedRooms: Array<RoomInfo>;
}

export enum QuizStatus {
    DRAFT = 'DRAFT',
    READY = 'READY',
    IN_REVIEW = 'IN_REVIEW',
    REJECTED = 'REJECTED'
}

export enum QuestionType {
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO'
}

export enum AnswerType {
    SPEED = 'SPEED',
    ONE = 'ONE',
    MULTI = 'MULTI',
    CUSTOM = 'CUSTOM',
    HOSTED = 'HOSTED'
}

export interface AnswerInfo {
    value: string;
    number: number;
    correct: boolean;
}

export interface QuizQuestionInfo {
    id?: string;
    createdBy?: string;
    number: number;
    type: QuestionType;
    value: string;
    media?: string;
    answerType: AnswerType;
    answers?: Array<AnswerInfo>;
    correctAnswer?: string;
    answerDescriptionType?: QuestionType;
    answerDescriptionValue?: string;
    answerDescriptionMedia?: string;
    timeout: number;
    points: number;
    progressivePoints?: boolean;
    answerOnce?: boolean;
}

export interface QuestionTemplateInfo {
    id?: string;
    createTime?: string;
    lastUpdateTime?: string;
    createdBy?: string;
    type: QuestionType;
    value: string;
    media?: string;
    answerType: AnswerType;
    answers?: Array<AnswerInfo>;
    correctAnswer?: string;
    answerDescriptionType?: QuestionType;
    answerDescriptionValue?: string;
    answerDescriptionMedia?: string;
}

@Injectable()
export class QuizConstructorService {

    private quizList: Array<QuizInfo>;
    private quiz: QuizInfo;
    // public quizListSubject = new Subject<Array<QuizInfo>>();
    public quizSubject = new Subject<QuizInfo>();

    public questionFile: Blob;

    constructor(
        private httpClient: HttpClient,
        private router: Router
    ) { }

    public getQuiz(quizId: string): Observable<QuizInfo> {
        return this.httpClient.post<QuizInfo>(
            environment.URLPrefix + '/quiz/get',
            JSON.stringify({
                id: quizId
            })
        );
    }

    public getSharedQuiz(shareLink: string): Observable<QuizInfo> {
        return this.httpClient.post<QuizInfo>(
            environment.URLPrefix + '/quiz/get',
            JSON.stringify({
                shareLink
            })
        );
    }

    public getAllQuizzes(): Observable<Array<QuizInfo>> {
        return this.httpClient.get<Array<QuizInfo>>(
            environment.URLPrefix + '/quiz/getAll',
            {}
        );
    }

    public getUserRooms(): Observable<UserRooms> {
        return this.httpClient.post<UserRooms>(
            environment.URLPrefix + '/user/rooms',
            {}
        ).pipe(
            map(userRooms => {
                return {
                    createdRooms: userRooms.createdRooms.filter(room => room.gameName === 'Quiz'),
                    startedRooms: userRooms.startedRooms.filter(room => room.gameName === 'Quiz'),
                    finishedRooms: userRooms.finishedRooms.filter(room => room.gameName === 'Quiz')
                };
            })
        );
    }

    public getAllModeratingQuizzes(): Observable<Array<QuizInfo>> {
        return this.httpClient.get<Array<QuizInfo>>(
            environment.URLPrefix + '/quiz/admin/getForReview',
            {}
        );
    }

    public createQuiz(quizInfo: QuizDescriptionInfo): Observable<string> {
        return this.httpClient.post<{id: string}>(
            environment.URLPrefix + '/quiz/create',
            JSON.stringify(quizInfo)
        ).pipe(
            map(result => result.id)
        );
    }

    public updateQuiz(quizInfo: QuizInfo): Observable<boolean> {
        return this.httpClient.put<{accept: boolean}>(
            environment.URLPrefix + '/quiz/update',
            JSON.stringify({
                id: quizInfo.id,
                name: quizInfo.name,
                description: quizInfo.description,
                public: quizInfo.public,
                status: quizInfo.status
            })
        ).pipe(
            map(result => result.accept)
        );
    }

    public enableQuizCreationShare(quizId: string): Observable<string> {
        return this.httpClient.post<{shareLink: string}>(
            environment.URLPrefix + '/quiz/share',
            JSON.stringify({
                id: quizId,
                days: 14
            })
        ).pipe(
            map(result => result.shareLink)
        );
    }

    public disableQuizCreationShare(quizId: string): Observable<boolean> {
        return this.httpClient.post<{result: boolean}>(
            environment.URLPrefix + '/quiz/unshare',
            JSON.stringify({
                id: quizId
            })
        ).pipe(
            map(result => result.result)
        );
    }

    public reviewQuiz(quizInfo: QuizInfo): Observable<boolean> {
        return this.httpClient.post<{accept: boolean}>(
            environment.URLPrefix + '/quiz/admin/review',
            JSON.stringify({
                id: quizInfo.id,
                status: quizInfo.status,
                rejectionReason: quizInfo.rejectionReason
            })
        ).pipe(
            map(result => result.accept)
        );
    }

    public deleteQuiz(quizId: string): Observable<boolean> {
        return this.httpClient.post<{accept: boolean}>(
            environment.URLPrefix + '/quiz/delete',
            JSON.stringify({
                id: quizId
            })
        ).pipe(
            map(result => result.accept)
        );
    }

    public createQuestion(quizInfo: QuizInfo, quizQuestionInfo: QuizQuestionInfo): Observable<{id: string}> {
        // If answer type without prepared answers, we need to clean answers list from null fields
        const fixedQuizQuestionInfo = {...quizQuestionInfo};
        if (
            quizQuestionInfo.answerType === AnswerType.SPEED
            || quizQuestionInfo.answerType === AnswerType.CUSTOM
            || quizQuestionInfo.answerType === AnswerType.HOSTED
        ) {
            fixedQuizQuestionInfo.answers = [];
        }
        return this.httpClient.post<{id: string}>(
            environment.URLPrefix + '/quiz/question/create',
            JSON.stringify({
                quizId: quizInfo.id,
                shareLink: quizInfo.shareLink ? quizInfo.shareLink : null,
                question: fixedQuizQuestionInfo
            })
        );
    }

    public createQuestionTemplate(quizQuestionInfo: QuizQuestionInfo): Observable<{id: string}> {
        // If answer type without prepared answers, we need to clean answers list from null fields
        const fixedQuizQuestionInfo = {...quizQuestionInfo};
        if (
            quizQuestionInfo.answerType === AnswerType.SPEED
            || quizQuestionInfo.answerType === AnswerType.CUSTOM
            || quizQuestionInfo.answerType === AnswerType.HOSTED
        ) {
            fixedQuizQuestionInfo.answers = [];
        }
        const questionTemplate: QuestionTemplateInfo = {
            value: fixedQuizQuestionInfo.value,
            answerDescriptionValue: fixedQuizQuestionInfo.answerDescriptionValue,
            answers: fixedQuizQuestionInfo.answers,
            answerType: fixedQuizQuestionInfo.answerType,
            type: fixedQuizQuestionInfo.type,
            correctAnswer: fixedQuizQuestionInfo.correctAnswer,
            answerDescriptionType: fixedQuizQuestionInfo.answerDescriptionType
        };
        return this.httpClient.post<{id: string}>(
            environment.URLPrefix + '/quiz/admin/template/create',
            JSON.stringify({
                question: questionTemplate
            })
        );
    }

    public updateQuestion(quizQuestionInfo: QuizQuestionInfo): Observable<{result: boolean}> {
        // If answer type without prepared answers, we need to clean answers list from null fields
        const fixedQuizQuestionInfo = {...quizQuestionInfo};
        if (
            quizQuestionInfo.answerType === AnswerType.SPEED
            || quizQuestionInfo.answerType === AnswerType.CUSTOM
            || quizQuestionInfo.answerType === AnswerType.HOSTED
        ) {
            fixedQuizQuestionInfo.answers = [];
        }
        return this.httpClient.put<{result: boolean}>(
            environment.URLPrefix + '/quiz/question/update',
            JSON.stringify(fixedQuizQuestionInfo)
        );
    }

    public deleteQuestion(questionId: string): Observable<{result: boolean}> {
        return this.httpClient.post<{result: boolean}>(
            environment.URLPrefix + '/quiz/question/delete',
            JSON.stringify({
                id: questionId
            })
        );
    }

    public getQuestionTemplateList(): Observable<Array<QuestionTemplateInfo>> {
        return this.httpClient.get<Array<QuestionTemplateInfo>>(
            environment.URLPrefix + '/quiz/template/getAll',
            {}
        );
    }
}
