import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-user-header',
    templateUrl: './user-header.component.html',
    styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent {
    @Input() public numberOfQuestions: number = null;
    @Input() public questionNumber: number = null;
}
