import { Injectable, OnDestroy } from '@angular/core';
import { WebSocketConnect } from '../../libs/websocket/WebSocketConnect';
import { StreamReader } from '../../libs/websocket/StreamReader';
import {
    CreateRoomMessageRequest,
    CreateRoomMessageResponse,
    JoinRoomMessageRequest,
    JoinRoomMessageResponse,
    RoomGeneralMessage,
    RoomMessageType,
    RoomPingMessage,
    RoomStartGameMessageRequest,
    RoomStartGameMessageResponse,
    RoomStateChangeMessageResponse,
    WaitRoomMessageRequest,
    WaitRoomMessageResponse
} from '../../../proto/generated/room_pb';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class RoomManagerService implements OnDestroy {

    ws: WebSocketConnect;
    // streamReader: StreamReader;
    public joinRoomMessageResponseSubject = new Subject<JoinRoomMessageResponse>();
    public createRoomMessageResponseSubject = new Subject<CreateRoomMessageResponse>();
    public waitRoomMessageResponseSubject = new Subject<WaitRoomMessageResponse>();
    public roomStateChangeMessageResponseSubject = new Subject<RoomStateChangeMessageResponse>();
    public roomStartGameMessageResponseSubject = new Subject<RoomStartGameMessageResponse>();

    constructor() {
        console.log('ROOM SOCKET CONSTRUCTOR');
        this.ws = new WebSocketConnect(environment.wsRoomURL, null, 'arraybuffer', true);
        this.ws.init();
        // this.streamReader = new StreamReader(this.ws);
        this.ws.onconnect = () => this.connectionHandler();
        this.ws.onreconnect = () => this.ws.enableHeartbeat('room');
        this.ws.onmessage = (msg) => this.messageHandler(msg);
        this.ws.onclose = () => this.connectionCloseHandler();
        this.ws.enableHeartbeat('room');
    }

    public connectionHandler() {
        console.log('Connected to the remote');
        const msg = this.createGeneralMessage();
        msg.setMessagetype(RoomMessageType.ROOM_MESSAGE_TYPE_PING);
        const pingMsg = new RoomPingMessage();
        // pingMsg.setHash('numberOfMessage');
        msg.setRoompingmessage(pingMsg);
        this.ws.send(msg.serializeBinary());
        // this.ws.send('message string');
    }

    public messageHandler(msg: any) {
        const response = RoomGeneralMessage.deserializeBinary(msg);
        let messageType = 'UNKNOWN';
        for (const typesKey in RoomMessageType) {
            if (RoomMessageType[typesKey] === response.getMessagetype()) {
                messageType = typesKey;
                break;
            }
        }
        switch (response.getMessagetype()) {
            case RoomMessageType.ROOM_MESSAGE_TYPE_CREATE_ROOM_RESPONSE:
                this.createRoomMessageResponseSubject.next(response.getCreateroommessageresponse());
                break;
            case RoomMessageType.ROOM_MESSAGE_TYPE_JOIN_ROOM_RESPONSE:
                this.joinRoomMessageResponseSubject.next(response.getJoinroommessageresponse());
                break;
            case RoomMessageType.ROOM_MESSAGE_TYPE_WAIT_ROOM_RESPONSE:
                this.waitRoomMessageResponseSubject.next(response.getWaitroommessageresponse());
                break;
            case RoomMessageType.ROOM_MESSAGE_TYPE_ROOM_STATE_CHANGE_RESPONSE:
                if (response.getRoomstatechangemessageresponse().getChanged()) {
                    this.roomStatusRequest(localStorage.getItem('roomId'));
                }
                break;
            case RoomMessageType.ROOM_MESSAGE_TYPE_ROOM_START_GAME_RESPONSE:
                this.disconnect();
                this.roomStartGameMessageResponseSubject.next(response.getRoomstartgamemessageresponse());
                break;
            case RoomMessageType.ROOM_MESSAGE_TYPE_ERROR_SERVER:
                console.log(response.getRoomerrorservermessage().getErrormessage());
                break;
        }

        console.log('Handle message with type: ', messageType);

    }

    public connectionCloseHandler() {
        console.log('Connection closed');
    }

    public connect() {
        console.log('Connecting');
        this.ws.init();
        /*
         let a = this.service.messages$.pipe(
         map(rows => rows.data),
         catchError(error => { throw error }),
         tap({
         error: error => console.log('[Live component] Error:', error),
         complete: () => console.log('[Live component] Connection Closed')
         })
         ).subscribe(msg => {
         console.log(msg)
         })
         */
        // this.service.sendMessage({c: 'd'})
        // a.unsubscribe()
        // this.service.close()
    }

    public createRoom(quizId: string, quizType: 'AI_TEXT' | 'SONG' | 'EMPTY', quizTopic?: string) {
        console.log('SEND CREATE ROOM MESSAGE');
        // subject.subscribe()

        // subject.next({a: 'b'})
        // subject.complete()
        // alert(123)
        // this.service.sendMessage({a: 'b'})
        // console.log(654)
        const msg = new RoomGeneralMessage();
        msg.setCookie(localStorage.getItem('sid'));
        msg.setAppversion(environment.appVersion);
        msg.setMessagetype(RoomMessageType.ROOM_MESSAGE_TYPE_CREATE_ROOM_REQUEST);
        const createRoomMessageRequest = new CreateRoomMessageRequest();
        createRoomMessageRequest.setGamename('Quiz');
        createRoomMessageRequest.setAllowsdisconnect(true);
        createRoomMessageRequest.setPrivateroom(false);
        createRoomMessageRequest.setMinplayers(2);
        createRoomMessageRequest.setMaxplayers(1000);
        if (quizType && quizTopic) {
            createRoomMessageRequest.setAdditionalgameparameters(JSON.stringify({quizId, quizType, quizTopic}));
        } else {
            createRoomMessageRequest.setAdditionalgameparameters(JSON.stringify({quizId}));
        }
        msg.setCreateroommessagerequest(createRoomMessageRequest);
        // const errorMsg = new RoomErrorClientMessage();
        // errorMsg.setErrorcode('code');
        // errorMsg.setErrormessage('message');
        // msg.setErrorclientmessage(errorMsg);
        // console.log(msg.serializeBinary());
        console.log(msg);
        this.ws.send(msg.serializeBinary());
        // this.ws.send('hello again ' + this.v)
    }

    public joinRoom(roomId: string) {
        console.log('SEND JOIN ROOM MESSAGE');
        const msg = this.createGeneralMessage();
        msg.setMessagetype(RoomMessageType.ROOM_MESSAGE_TYPE_JOIN_ROOM_REQUEST);
        const joinRoomMessageRequest = new JoinRoomMessageRequest();
        joinRoomMessageRequest.setGamename('Quiz');
        joinRoomMessageRequest.setRoomidorname(roomId);
        msg.setJoinroommessagerequest(joinRoomMessageRequest);
        this.ws.send(msg.serializeBinary());
    }

    public roomStatusRequest(roomId: string) {
        console.log('SEND ROOM STATUS MESSAGE');
        const msg = this.createGeneralMessage();
        msg.setMessagetype(RoomMessageType.ROOM_MESSAGE_TYPE_WAIT_ROOM_REQUEST);
        const waitRoomMessageRequest = new WaitRoomMessageRequest();
        waitRoomMessageRequest.setGamename('Quiz');
        waitRoomMessageRequest.setRoomid(roomId);
        msg.setWaitroommessagerequest(waitRoomMessageRequest);
        this.ws.send(msg.serializeBinary());
    }

    public startRequest(roomId: string) {
        console.log('SEND START MESSAGE');
        const msg = this.createGeneralMessage();
        msg.setMessagetype(RoomMessageType.ROOM_MESSAGE_TYPE_ROOM_START_GAME_REQUEST);
        const roomStartGameMessageRequest = new RoomStartGameMessageRequest();
        roomStartGameMessageRequest.setGamename('Quiz');
        roomStartGameMessageRequest.setRoomid(roomId);
        msg.setRoomstartgamemessagerequest(roomStartGameMessageRequest);
        this.ws.send(msg.serializeBinary());
    }

    public disconnect() {
        this.ws.close();
    }

    private createGeneralMessage(): RoomGeneralMessage {
        const msg = new RoomGeneralMessage();
        msg.setCookie(localStorage.getItem('sid'));
        msg.setAppversion(environment.appVersion);
        return msg;
    }

    ngOnDestroy() {
        this.ws.close();
    }
}
