import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AiPartygamesComponent } from './ai-partygames.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TopicSelectorDialogComponent } from './topic-selector-dialog/topic-selector-dialog.component';
import { I18NModule } from '../../libs/translate/i18n.module';
import { MaterialAllInOneModule } from '../../libs/material-all-in-one/material-all-in-one.module';
import { AiQuizComponent } from './ai-quiz/ai-quiz.component';

@NgModule({
    declarations: [
        AiPartygamesComponent,
        TopicSelectorDialogComponent,
        AiQuizComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        RouterLink,
        RouterLinkActive,
        I18NModule.forChild(),
        MaterialAllInOneModule
    ]
})
export class AiPartygamesModule {}
