// source: room.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.app.gambo.CreateRoomMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.CreateRoomMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.JoinRandomRoomMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.JoinRandomRoomMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo', null, global);
goog.exportSymbol('proto.app.gambo.JoinRoomMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.JoinRoomMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.PlayerInfo', null, global);
goog.exportSymbol('proto.app.gambo.RoomAddTeamMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.RoomAddTeamMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.RoomChangeTeamMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.RoomChangeTeamMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.RoomChangeTeamNameMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.RoomChangeTeamNameMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.RoomErrorClientMessage', null, global);
goog.exportSymbol('proto.app.gambo.RoomErrorServerMessage', null, global);
goog.exportSymbol('proto.app.gambo.RoomGeneralMessage', null, global);
goog.exportSymbol('proto.app.gambo.RoomGeneralMessage.MsgCase', null, global);
goog.exportSymbol('proto.app.gambo.RoomMessageType', null, global);
goog.exportSymbol('proto.app.gambo.RoomPingMessage', null, global);
goog.exportSymbol('proto.app.gambo.RoomPongMessage', null, global);
goog.exportSymbol('proto.app.gambo.RoomRemoveTeamMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.RoomRemoveTeamMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.RoomStartGameMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.RoomStartGameMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.RoomStateChangeMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.RoomStateChangeMessageResponse', null, global);
goog.exportSymbol('proto.app.gambo.TeamInfo', null, global);
goog.exportSymbol('proto.app.gambo.WaitRoomMessageRequest', null, global);
goog.exportSymbol('proto.app.gambo.WaitRoomMessageResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomGeneralMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.app.gambo.RoomGeneralMessage.oneofGroups_);
};
goog.inherits(proto.app.gambo.RoomGeneralMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomGeneralMessage.displayName = 'proto.app.gambo.RoomGeneralMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomPingMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomPingMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomPingMessage.displayName = 'proto.app.gambo.RoomPingMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomPongMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomPongMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomPongMessage.displayName = 'proto.app.gambo.RoomPongMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomErrorServerMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomErrorServerMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomErrorServerMessage.displayName = 'proto.app.gambo.RoomErrorServerMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomErrorClientMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomErrorClientMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomErrorClientMessage.displayName = 'proto.app.gambo.RoomErrorClientMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.CreateRoomMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.CreateRoomMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.CreateRoomMessageRequest.displayName = 'proto.app.gambo.CreateRoomMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.CreateRoomMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.CreateRoomMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.CreateRoomMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.CreateRoomMessageResponse.displayName = 'proto.app.gambo.CreateRoomMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.JoinRandomRoomMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.JoinRandomRoomMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.JoinRandomRoomMessageRequest.displayName = 'proto.app.gambo.JoinRandomRoomMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.JoinRandomRoomMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.JoinRandomRoomMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.JoinRandomRoomMessageResponse.displayName = 'proto.app.gambo.JoinRandomRoomMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.displayName = 'proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.JoinRoomMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.JoinRoomMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.JoinRoomMessageRequest.displayName = 'proto.app.gambo.JoinRoomMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.JoinRoomMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.JoinRoomMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.JoinRoomMessageResponse.displayName = 'proto.app.gambo.JoinRoomMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.TeamInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.TeamInfo.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.TeamInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.TeamInfo.displayName = 'proto.app.gambo.TeamInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.PlayerInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.PlayerInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.PlayerInfo.displayName = 'proto.app.gambo.PlayerInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.WaitRoomMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.WaitRoomMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.WaitRoomMessageRequest.displayName = 'proto.app.gambo.WaitRoomMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.WaitRoomMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.gambo.WaitRoomMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.gambo.WaitRoomMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.WaitRoomMessageResponse.displayName = 'proto.app.gambo.WaitRoomMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomStateChangeMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomStateChangeMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomStateChangeMessageRequest.displayName = 'proto.app.gambo.RoomStateChangeMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomStateChangeMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomStateChangeMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomStateChangeMessageResponse.displayName = 'proto.app.gambo.RoomStateChangeMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomStartGameMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomStartGameMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomStartGameMessageRequest.displayName = 'proto.app.gambo.RoomStartGameMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomStartGameMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomStartGameMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomStartGameMessageResponse.displayName = 'proto.app.gambo.RoomStartGameMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomChangeTeamMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomChangeTeamMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomChangeTeamMessageRequest.displayName = 'proto.app.gambo.RoomChangeTeamMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomChangeTeamMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomChangeTeamMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomChangeTeamMessageResponse.displayName = 'proto.app.gambo.RoomChangeTeamMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomChangeTeamNameMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomChangeTeamNameMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomChangeTeamNameMessageRequest.displayName = 'proto.app.gambo.RoomChangeTeamNameMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomChangeTeamNameMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomChangeTeamNameMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomChangeTeamNameMessageResponse.displayName = 'proto.app.gambo.RoomChangeTeamNameMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomAddTeamMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomAddTeamMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomAddTeamMessageRequest.displayName = 'proto.app.gambo.RoomAddTeamMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomAddTeamMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomAddTeamMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomAddTeamMessageResponse.displayName = 'proto.app.gambo.RoomAddTeamMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomRemoveTeamMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomRemoveTeamMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomRemoveTeamMessageRequest.displayName = 'proto.app.gambo.RoomRemoveTeamMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.gambo.RoomRemoveTeamMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.gambo.RoomRemoveTeamMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.gambo.RoomRemoveTeamMessageResponse.displayName = 'proto.app.gambo.RoomRemoveTeamMessageResponse';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.app.gambo.RoomGeneralMessage.oneofGroups_ = [[4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27]];

/**
 * @enum {number}
 */
proto.app.gambo.RoomGeneralMessage.MsgCase = {
  MSG_NOT_SET: 0,
  ROOMPINGMESSAGE: 4,
  ROOMPONGMESSAGE: 5,
  ROOMERRORSERVERMESSAGE: 6,
  ROOMERRORCLIENTMESSAGE: 7,
  CREATEROOMMESSAGEREQUEST: 8,
  CREATEROOMMESSAGERESPONSE: 9,
  JOINRANDOMROOMMESSAGEREQUEST: 10,
  JOINRANDOMROOMMESSAGERESPONSE: 11,
  JOINROOMMESSAGEREQUEST: 12,
  JOINROOMMESSAGERESPONSE: 13,
  WAITROOMMESSAGEREQUEST: 14,
  WAITROOMMESSAGERESPONSE: 15,
  ROOMSTATECHANGEMESSAGEREQUEST: 16,
  ROOMSTATECHANGEMESSAGERESPONSE: 17,
  ROOMSTARTGAMEMESSAGEREQUEST: 18,
  ROOMSTARTGAMEMESSAGERESPONSE: 19,
  ROOMCHANGETEAMMESSAGEREQUEST: 20,
  ROOMCHANGETEAMMESSAGERESPONSE: 21,
  ROOMCHANGETEAMNAMEMESSAGEREQUEST: 22,
  ROOMCHANGETEAMNAMEMESSAGERESPONSE: 23,
  ROOMADDTEAMMESSAGEREQUEST: 24,
  ROOMADDTEAMMESSAGERESPONSE: 25,
  ROOMREMOVETEAMMESSAGEREQUEST: 26,
  ROOMREMOVETEAMMESSAGERESPONSE: 27
};

/**
 * @return {proto.app.gambo.RoomGeneralMessage.MsgCase}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getMsgCase = function() {
  return /** @type {proto.app.gambo.RoomGeneralMessage.MsgCase} */(jspb.Message.computeOneofCase(this, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomGeneralMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomGeneralMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomGeneralMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomGeneralMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    messagetype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cookie: jspb.Message.getFieldWithDefault(msg, 2, ""),
    appversion: jspb.Message.getFieldWithDefault(msg, 3, ""),
    roompingmessage: (f = msg.getRoompingmessage()) && proto.app.gambo.RoomPingMessage.toObject(includeInstance, f),
    roompongmessage: (f = msg.getRoompongmessage()) && proto.app.gambo.RoomPongMessage.toObject(includeInstance, f),
    roomerrorservermessage: (f = msg.getRoomerrorservermessage()) && proto.app.gambo.RoomErrorServerMessage.toObject(includeInstance, f),
    roomerrorclientmessage: (f = msg.getRoomerrorclientmessage()) && proto.app.gambo.RoomErrorClientMessage.toObject(includeInstance, f),
    createroommessagerequest: (f = msg.getCreateroommessagerequest()) && proto.app.gambo.CreateRoomMessageRequest.toObject(includeInstance, f),
    createroommessageresponse: (f = msg.getCreateroommessageresponse()) && proto.app.gambo.CreateRoomMessageResponse.toObject(includeInstance, f),
    joinrandomroommessagerequest: (f = msg.getJoinrandomroommessagerequest()) && proto.app.gambo.JoinRandomRoomMessageRequest.toObject(includeInstance, f),
    joinrandomroommessageresponse: (f = msg.getJoinrandomroommessageresponse()) && proto.app.gambo.JoinRandomRoomMessageResponse.toObject(includeInstance, f),
    joinroommessagerequest: (f = msg.getJoinroommessagerequest()) && proto.app.gambo.JoinRoomMessageRequest.toObject(includeInstance, f),
    joinroommessageresponse: (f = msg.getJoinroommessageresponse()) && proto.app.gambo.JoinRoomMessageResponse.toObject(includeInstance, f),
    waitroommessagerequest: (f = msg.getWaitroommessagerequest()) && proto.app.gambo.WaitRoomMessageRequest.toObject(includeInstance, f),
    waitroommessageresponse: (f = msg.getWaitroommessageresponse()) && proto.app.gambo.WaitRoomMessageResponse.toObject(includeInstance, f),
    roomstatechangemessagerequest: (f = msg.getRoomstatechangemessagerequest()) && proto.app.gambo.RoomStateChangeMessageRequest.toObject(includeInstance, f),
    roomstatechangemessageresponse: (f = msg.getRoomstatechangemessageresponse()) && proto.app.gambo.RoomStateChangeMessageResponse.toObject(includeInstance, f),
    roomstartgamemessagerequest: (f = msg.getRoomstartgamemessagerequest()) && proto.app.gambo.RoomStartGameMessageRequest.toObject(includeInstance, f),
    roomstartgamemessageresponse: (f = msg.getRoomstartgamemessageresponse()) && proto.app.gambo.RoomStartGameMessageResponse.toObject(includeInstance, f),
    roomchangeteammessagerequest: (f = msg.getRoomchangeteammessagerequest()) && proto.app.gambo.RoomChangeTeamMessageRequest.toObject(includeInstance, f),
    roomchangeteammessageresponse: (f = msg.getRoomchangeteammessageresponse()) && proto.app.gambo.RoomChangeTeamMessageResponse.toObject(includeInstance, f),
    roomchangeteamnamemessagerequest: (f = msg.getRoomchangeteamnamemessagerequest()) && proto.app.gambo.RoomChangeTeamNameMessageRequest.toObject(includeInstance, f),
    roomchangeteamnamemessageresponse: (f = msg.getRoomchangeteamnamemessageresponse()) && proto.app.gambo.RoomChangeTeamNameMessageResponse.toObject(includeInstance, f),
    roomaddteammessagerequest: (f = msg.getRoomaddteammessagerequest()) && proto.app.gambo.RoomAddTeamMessageRequest.toObject(includeInstance, f),
    roomaddteammessageresponse: (f = msg.getRoomaddteammessageresponse()) && proto.app.gambo.RoomAddTeamMessageResponse.toObject(includeInstance, f),
    roomremoveteammessagerequest: (f = msg.getRoomremoveteammessagerequest()) && proto.app.gambo.RoomRemoveTeamMessageRequest.toObject(includeInstance, f),
    roomremoveteammessageresponse: (f = msg.getRoomremoveteammessageresponse()) && proto.app.gambo.RoomRemoveTeamMessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomGeneralMessage}
 */
proto.app.gambo.RoomGeneralMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomGeneralMessage;
  return proto.app.gambo.RoomGeneralMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomGeneralMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomGeneralMessage}
 */
proto.app.gambo.RoomGeneralMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.app.gambo.RoomMessageType} */ (reader.readEnum());
      msg.setMessagetype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCookie(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppversion(value);
      break;
    case 4:
      var value = new proto.app.gambo.RoomPingMessage;
      reader.readMessage(value,proto.app.gambo.RoomPingMessage.deserializeBinaryFromReader);
      msg.setRoompingmessage(value);
      break;
    case 5:
      var value = new proto.app.gambo.RoomPongMessage;
      reader.readMessage(value,proto.app.gambo.RoomPongMessage.deserializeBinaryFromReader);
      msg.setRoompongmessage(value);
      break;
    case 6:
      var value = new proto.app.gambo.RoomErrorServerMessage;
      reader.readMessage(value,proto.app.gambo.RoomErrorServerMessage.deserializeBinaryFromReader);
      msg.setRoomerrorservermessage(value);
      break;
    case 7:
      var value = new proto.app.gambo.RoomErrorClientMessage;
      reader.readMessage(value,proto.app.gambo.RoomErrorClientMessage.deserializeBinaryFromReader);
      msg.setRoomerrorclientmessage(value);
      break;
    case 8:
      var value = new proto.app.gambo.CreateRoomMessageRequest;
      reader.readMessage(value,proto.app.gambo.CreateRoomMessageRequest.deserializeBinaryFromReader);
      msg.setCreateroommessagerequest(value);
      break;
    case 9:
      var value = new proto.app.gambo.CreateRoomMessageResponse;
      reader.readMessage(value,proto.app.gambo.CreateRoomMessageResponse.deserializeBinaryFromReader);
      msg.setCreateroommessageresponse(value);
      break;
    case 10:
      var value = new proto.app.gambo.JoinRandomRoomMessageRequest;
      reader.readMessage(value,proto.app.gambo.JoinRandomRoomMessageRequest.deserializeBinaryFromReader);
      msg.setJoinrandomroommessagerequest(value);
      break;
    case 11:
      var value = new proto.app.gambo.JoinRandomRoomMessageResponse;
      reader.readMessage(value,proto.app.gambo.JoinRandomRoomMessageResponse.deserializeBinaryFromReader);
      msg.setJoinrandomroommessageresponse(value);
      break;
    case 12:
      var value = new proto.app.gambo.JoinRoomMessageRequest;
      reader.readMessage(value,proto.app.gambo.JoinRoomMessageRequest.deserializeBinaryFromReader);
      msg.setJoinroommessagerequest(value);
      break;
    case 13:
      var value = new proto.app.gambo.JoinRoomMessageResponse;
      reader.readMessage(value,proto.app.gambo.JoinRoomMessageResponse.deserializeBinaryFromReader);
      msg.setJoinroommessageresponse(value);
      break;
    case 14:
      var value = new proto.app.gambo.WaitRoomMessageRequest;
      reader.readMessage(value,proto.app.gambo.WaitRoomMessageRequest.deserializeBinaryFromReader);
      msg.setWaitroommessagerequest(value);
      break;
    case 15:
      var value = new proto.app.gambo.WaitRoomMessageResponse;
      reader.readMessage(value,proto.app.gambo.WaitRoomMessageResponse.deserializeBinaryFromReader);
      msg.setWaitroommessageresponse(value);
      break;
    case 16:
      var value = new proto.app.gambo.RoomStateChangeMessageRequest;
      reader.readMessage(value,proto.app.gambo.RoomStateChangeMessageRequest.deserializeBinaryFromReader);
      msg.setRoomstatechangemessagerequest(value);
      break;
    case 17:
      var value = new proto.app.gambo.RoomStateChangeMessageResponse;
      reader.readMessage(value,proto.app.gambo.RoomStateChangeMessageResponse.deserializeBinaryFromReader);
      msg.setRoomstatechangemessageresponse(value);
      break;
    case 18:
      var value = new proto.app.gambo.RoomStartGameMessageRequest;
      reader.readMessage(value,proto.app.gambo.RoomStartGameMessageRequest.deserializeBinaryFromReader);
      msg.setRoomstartgamemessagerequest(value);
      break;
    case 19:
      var value = new proto.app.gambo.RoomStartGameMessageResponse;
      reader.readMessage(value,proto.app.gambo.RoomStartGameMessageResponse.deserializeBinaryFromReader);
      msg.setRoomstartgamemessageresponse(value);
      break;
    case 20:
      var value = new proto.app.gambo.RoomChangeTeamMessageRequest;
      reader.readMessage(value,proto.app.gambo.RoomChangeTeamMessageRequest.deserializeBinaryFromReader);
      msg.setRoomchangeteammessagerequest(value);
      break;
    case 21:
      var value = new proto.app.gambo.RoomChangeTeamMessageResponse;
      reader.readMessage(value,proto.app.gambo.RoomChangeTeamMessageResponse.deserializeBinaryFromReader);
      msg.setRoomchangeteammessageresponse(value);
      break;
    case 22:
      var value = new proto.app.gambo.RoomChangeTeamNameMessageRequest;
      reader.readMessage(value,proto.app.gambo.RoomChangeTeamNameMessageRequest.deserializeBinaryFromReader);
      msg.setRoomchangeteamnamemessagerequest(value);
      break;
    case 23:
      var value = new proto.app.gambo.RoomChangeTeamNameMessageResponse;
      reader.readMessage(value,proto.app.gambo.RoomChangeTeamNameMessageResponse.deserializeBinaryFromReader);
      msg.setRoomchangeteamnamemessageresponse(value);
      break;
    case 24:
      var value = new proto.app.gambo.RoomAddTeamMessageRequest;
      reader.readMessage(value,proto.app.gambo.RoomAddTeamMessageRequest.deserializeBinaryFromReader);
      msg.setRoomaddteammessagerequest(value);
      break;
    case 25:
      var value = new proto.app.gambo.RoomAddTeamMessageResponse;
      reader.readMessage(value,proto.app.gambo.RoomAddTeamMessageResponse.deserializeBinaryFromReader);
      msg.setRoomaddteammessageresponse(value);
      break;
    case 26:
      var value = new proto.app.gambo.RoomRemoveTeamMessageRequest;
      reader.readMessage(value,proto.app.gambo.RoomRemoveTeamMessageRequest.deserializeBinaryFromReader);
      msg.setRoomremoveteammessagerequest(value);
      break;
    case 27:
      var value = new proto.app.gambo.RoomRemoveTeamMessageResponse;
      reader.readMessage(value,proto.app.gambo.RoomRemoveTeamMessageResponse.deserializeBinaryFromReader);
      msg.setRoomremoveteammessageresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomGeneralMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomGeneralMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomGeneralMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomGeneralMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessagetype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCookie();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAppversion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRoompingmessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.app.gambo.RoomPingMessage.serializeBinaryToWriter
    );
  }
  f = message.getRoompongmessage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.app.gambo.RoomPongMessage.serializeBinaryToWriter
    );
  }
  f = message.getRoomerrorservermessage();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.app.gambo.RoomErrorServerMessage.serializeBinaryToWriter
    );
  }
  f = message.getRoomerrorclientmessage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.app.gambo.RoomErrorClientMessage.serializeBinaryToWriter
    );
  }
  f = message.getCreateroommessagerequest();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.app.gambo.CreateRoomMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getCreateroommessageresponse();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.app.gambo.CreateRoomMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getJoinrandomroommessagerequest();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.app.gambo.JoinRandomRoomMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getJoinrandomroommessageresponse();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.app.gambo.JoinRandomRoomMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getJoinroommessagerequest();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.app.gambo.JoinRoomMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getJoinroommessageresponse();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.app.gambo.JoinRoomMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getWaitroommessagerequest();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.app.gambo.WaitRoomMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getWaitroommessageresponse();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.app.gambo.WaitRoomMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getRoomstatechangemessagerequest();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.app.gambo.RoomStateChangeMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getRoomstatechangemessageresponse();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.app.gambo.RoomStateChangeMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getRoomstartgamemessagerequest();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.app.gambo.RoomStartGameMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getRoomstartgamemessageresponse();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.app.gambo.RoomStartGameMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getRoomchangeteammessagerequest();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.app.gambo.RoomChangeTeamMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getRoomchangeteammessageresponse();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.app.gambo.RoomChangeTeamMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getRoomchangeteamnamemessagerequest();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.app.gambo.RoomChangeTeamNameMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getRoomchangeteamnamemessageresponse();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.app.gambo.RoomChangeTeamNameMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getRoomaddteammessagerequest();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.app.gambo.RoomAddTeamMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getRoomaddteammessageresponse();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.app.gambo.RoomAddTeamMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getRoomremoveteammessagerequest();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.app.gambo.RoomRemoveTeamMessageRequest.serializeBinaryToWriter
    );
  }
  f = message.getRoomremoveteammessageresponse();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.app.gambo.RoomRemoveTeamMessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoomMessageType messageType = 1;
 * @return {!proto.app.gambo.RoomMessageType}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getMessagetype = function() {
  return /** @type {!proto.app.gambo.RoomMessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.app.gambo.RoomMessageType} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.setMessagetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string cookie = 2;
 * @return {string}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getCookie = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.setCookie = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string appVersion = 3;
 * @return {string}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getAppversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.setAppversion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional RoomPingMessage roomPingMessage = 4;
 * @return {?proto.app.gambo.RoomPingMessage}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoompingmessage = function() {
  return /** @type{?proto.app.gambo.RoomPingMessage} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomPingMessage, 4));
};


/**
 * @param {?proto.app.gambo.RoomPingMessage|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoompingmessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoompingmessage = function() {
  return this.setRoompingmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoompingmessage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RoomPongMessage roomPongMessage = 5;
 * @return {?proto.app.gambo.RoomPongMessage}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoompongmessage = function() {
  return /** @type{?proto.app.gambo.RoomPongMessage} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomPongMessage, 5));
};


/**
 * @param {?proto.app.gambo.RoomPongMessage|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoompongmessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoompongmessage = function() {
  return this.setRoompongmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoompongmessage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional RoomErrorServerMessage roomErrorServerMessage = 6;
 * @return {?proto.app.gambo.RoomErrorServerMessage}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoomerrorservermessage = function() {
  return /** @type{?proto.app.gambo.RoomErrorServerMessage} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomErrorServerMessage, 6));
};


/**
 * @param {?proto.app.gambo.RoomErrorServerMessage|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoomerrorservermessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoomerrorservermessage = function() {
  return this.setRoomerrorservermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoomerrorservermessage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional RoomErrorClientMessage roomErrorClientMessage = 7;
 * @return {?proto.app.gambo.RoomErrorClientMessage}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoomerrorclientmessage = function() {
  return /** @type{?proto.app.gambo.RoomErrorClientMessage} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomErrorClientMessage, 7));
};


/**
 * @param {?proto.app.gambo.RoomErrorClientMessage|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoomerrorclientmessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoomerrorclientmessage = function() {
  return this.setRoomerrorclientmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoomerrorclientmessage = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional CreateRoomMessageRequest createRoomMessageRequest = 8;
 * @return {?proto.app.gambo.CreateRoomMessageRequest}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getCreateroommessagerequest = function() {
  return /** @type{?proto.app.gambo.CreateRoomMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.CreateRoomMessageRequest, 8));
};


/**
 * @param {?proto.app.gambo.CreateRoomMessageRequest|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setCreateroommessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearCreateroommessagerequest = function() {
  return this.setCreateroommessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasCreateroommessagerequest = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional CreateRoomMessageResponse createRoomMessageResponse = 9;
 * @return {?proto.app.gambo.CreateRoomMessageResponse}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getCreateroommessageresponse = function() {
  return /** @type{?proto.app.gambo.CreateRoomMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.CreateRoomMessageResponse, 9));
};


/**
 * @param {?proto.app.gambo.CreateRoomMessageResponse|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setCreateroommessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearCreateroommessageresponse = function() {
  return this.setCreateroommessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasCreateroommessageresponse = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional JoinRandomRoomMessageRequest joinRandomRoomMessageRequest = 10;
 * @return {?proto.app.gambo.JoinRandomRoomMessageRequest}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getJoinrandomroommessagerequest = function() {
  return /** @type{?proto.app.gambo.JoinRandomRoomMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.JoinRandomRoomMessageRequest, 10));
};


/**
 * @param {?proto.app.gambo.JoinRandomRoomMessageRequest|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setJoinrandomroommessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearJoinrandomroommessagerequest = function() {
  return this.setJoinrandomroommessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasJoinrandomroommessagerequest = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional JoinRandomRoomMessageResponse joinRandomRoomMessageResponse = 11;
 * @return {?proto.app.gambo.JoinRandomRoomMessageResponse}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getJoinrandomroommessageresponse = function() {
  return /** @type{?proto.app.gambo.JoinRandomRoomMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.JoinRandomRoomMessageResponse, 11));
};


/**
 * @param {?proto.app.gambo.JoinRandomRoomMessageResponse|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setJoinrandomroommessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearJoinrandomroommessageresponse = function() {
  return this.setJoinrandomroommessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasJoinrandomroommessageresponse = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional JoinRoomMessageRequest joinRoomMessageRequest = 12;
 * @return {?proto.app.gambo.JoinRoomMessageRequest}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getJoinroommessagerequest = function() {
  return /** @type{?proto.app.gambo.JoinRoomMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.JoinRoomMessageRequest, 12));
};


/**
 * @param {?proto.app.gambo.JoinRoomMessageRequest|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setJoinroommessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearJoinroommessagerequest = function() {
  return this.setJoinroommessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasJoinroommessagerequest = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional JoinRoomMessageResponse joinRoomMessageResponse = 13;
 * @return {?proto.app.gambo.JoinRoomMessageResponse}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getJoinroommessageresponse = function() {
  return /** @type{?proto.app.gambo.JoinRoomMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.JoinRoomMessageResponse, 13));
};


/**
 * @param {?proto.app.gambo.JoinRoomMessageResponse|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setJoinroommessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearJoinroommessageresponse = function() {
  return this.setJoinroommessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasJoinroommessageresponse = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional WaitRoomMessageRequest waitRoomMessageRequest = 14;
 * @return {?proto.app.gambo.WaitRoomMessageRequest}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getWaitroommessagerequest = function() {
  return /** @type{?proto.app.gambo.WaitRoomMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.WaitRoomMessageRequest, 14));
};


/**
 * @param {?proto.app.gambo.WaitRoomMessageRequest|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setWaitroommessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearWaitroommessagerequest = function() {
  return this.setWaitroommessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasWaitroommessagerequest = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional WaitRoomMessageResponse waitRoomMessageResponse = 15;
 * @return {?proto.app.gambo.WaitRoomMessageResponse}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getWaitroommessageresponse = function() {
  return /** @type{?proto.app.gambo.WaitRoomMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.WaitRoomMessageResponse, 15));
};


/**
 * @param {?proto.app.gambo.WaitRoomMessageResponse|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setWaitroommessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearWaitroommessageresponse = function() {
  return this.setWaitroommessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasWaitroommessageresponse = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional RoomStateChangeMessageRequest roomStateChangeMessageRequest = 16;
 * @return {?proto.app.gambo.RoomStateChangeMessageRequest}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoomstatechangemessagerequest = function() {
  return /** @type{?proto.app.gambo.RoomStateChangeMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomStateChangeMessageRequest, 16));
};


/**
 * @param {?proto.app.gambo.RoomStateChangeMessageRequest|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoomstatechangemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoomstatechangemessagerequest = function() {
  return this.setRoomstatechangemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoomstatechangemessagerequest = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional RoomStateChangeMessageResponse roomStateChangeMessageResponse = 17;
 * @return {?proto.app.gambo.RoomStateChangeMessageResponse}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoomstatechangemessageresponse = function() {
  return /** @type{?proto.app.gambo.RoomStateChangeMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomStateChangeMessageResponse, 17));
};


/**
 * @param {?proto.app.gambo.RoomStateChangeMessageResponse|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoomstatechangemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoomstatechangemessageresponse = function() {
  return this.setRoomstatechangemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoomstatechangemessageresponse = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional RoomStartGameMessageRequest roomStartGameMessageRequest = 18;
 * @return {?proto.app.gambo.RoomStartGameMessageRequest}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoomstartgamemessagerequest = function() {
  return /** @type{?proto.app.gambo.RoomStartGameMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomStartGameMessageRequest, 18));
};


/**
 * @param {?proto.app.gambo.RoomStartGameMessageRequest|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoomstartgamemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoomstartgamemessagerequest = function() {
  return this.setRoomstartgamemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoomstartgamemessagerequest = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional RoomStartGameMessageResponse roomStartGameMessageResponse = 19;
 * @return {?proto.app.gambo.RoomStartGameMessageResponse}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoomstartgamemessageresponse = function() {
  return /** @type{?proto.app.gambo.RoomStartGameMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomStartGameMessageResponse, 19));
};


/**
 * @param {?proto.app.gambo.RoomStartGameMessageResponse|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoomstartgamemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoomstartgamemessageresponse = function() {
  return this.setRoomstartgamemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoomstartgamemessageresponse = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional RoomChangeTeamMessageRequest roomChangeTeamMessageRequest = 20;
 * @return {?proto.app.gambo.RoomChangeTeamMessageRequest}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoomchangeteammessagerequest = function() {
  return /** @type{?proto.app.gambo.RoomChangeTeamMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomChangeTeamMessageRequest, 20));
};


/**
 * @param {?proto.app.gambo.RoomChangeTeamMessageRequest|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoomchangeteammessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoomchangeteammessagerequest = function() {
  return this.setRoomchangeteammessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoomchangeteammessagerequest = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional RoomChangeTeamMessageResponse roomChangeTeamMessageResponse = 21;
 * @return {?proto.app.gambo.RoomChangeTeamMessageResponse}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoomchangeteammessageresponse = function() {
  return /** @type{?proto.app.gambo.RoomChangeTeamMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomChangeTeamMessageResponse, 21));
};


/**
 * @param {?proto.app.gambo.RoomChangeTeamMessageResponse|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoomchangeteammessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoomchangeteammessageresponse = function() {
  return this.setRoomchangeteammessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoomchangeteammessageresponse = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional RoomChangeTeamNameMessageRequest roomChangeTeamNameMessageRequest = 22;
 * @return {?proto.app.gambo.RoomChangeTeamNameMessageRequest}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoomchangeteamnamemessagerequest = function() {
  return /** @type{?proto.app.gambo.RoomChangeTeamNameMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomChangeTeamNameMessageRequest, 22));
};


/**
 * @param {?proto.app.gambo.RoomChangeTeamNameMessageRequest|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoomchangeteamnamemessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoomchangeteamnamemessagerequest = function() {
  return this.setRoomchangeteamnamemessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoomchangeteamnamemessagerequest = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional RoomChangeTeamNameMessageResponse roomChangeTeamNameMessageResponse = 23;
 * @return {?proto.app.gambo.RoomChangeTeamNameMessageResponse}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoomchangeteamnamemessageresponse = function() {
  return /** @type{?proto.app.gambo.RoomChangeTeamNameMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomChangeTeamNameMessageResponse, 23));
};


/**
 * @param {?proto.app.gambo.RoomChangeTeamNameMessageResponse|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoomchangeteamnamemessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoomchangeteamnamemessageresponse = function() {
  return this.setRoomchangeteamnamemessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoomchangeteamnamemessageresponse = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional RoomAddTeamMessageRequest roomAddTeamMessageRequest = 24;
 * @return {?proto.app.gambo.RoomAddTeamMessageRequest}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoomaddteammessagerequest = function() {
  return /** @type{?proto.app.gambo.RoomAddTeamMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomAddTeamMessageRequest, 24));
};


/**
 * @param {?proto.app.gambo.RoomAddTeamMessageRequest|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoomaddteammessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoomaddteammessagerequest = function() {
  return this.setRoomaddteammessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoomaddteammessagerequest = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional RoomAddTeamMessageResponse roomAddTeamMessageResponse = 25;
 * @return {?proto.app.gambo.RoomAddTeamMessageResponse}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoomaddteammessageresponse = function() {
  return /** @type{?proto.app.gambo.RoomAddTeamMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomAddTeamMessageResponse, 25));
};


/**
 * @param {?proto.app.gambo.RoomAddTeamMessageResponse|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoomaddteammessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoomaddteammessageresponse = function() {
  return this.setRoomaddteammessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoomaddteammessageresponse = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional RoomRemoveTeamMessageRequest roomRemoveTeamMessageRequest = 26;
 * @return {?proto.app.gambo.RoomRemoveTeamMessageRequest}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoomremoveteammessagerequest = function() {
  return /** @type{?proto.app.gambo.RoomRemoveTeamMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomRemoveTeamMessageRequest, 26));
};


/**
 * @param {?proto.app.gambo.RoomRemoveTeamMessageRequest|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoomremoveteammessagerequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoomremoveteammessagerequest = function() {
  return this.setRoomremoveteammessagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoomremoveteammessagerequest = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional RoomRemoveTeamMessageResponse roomRemoveTeamMessageResponse = 27;
 * @return {?proto.app.gambo.RoomRemoveTeamMessageResponse}
 */
proto.app.gambo.RoomGeneralMessage.prototype.getRoomremoveteammessageresponse = function() {
  return /** @type{?proto.app.gambo.RoomRemoveTeamMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.RoomRemoveTeamMessageResponse, 27));
};


/**
 * @param {?proto.app.gambo.RoomRemoveTeamMessageResponse|undefined} value
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
*/
proto.app.gambo.RoomGeneralMessage.prototype.setRoomremoveteammessageresponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.app.gambo.RoomGeneralMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.RoomGeneralMessage} returns this
 */
proto.app.gambo.RoomGeneralMessage.prototype.clearRoomremoveteammessageresponse = function() {
  return this.setRoomremoveteammessageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.RoomGeneralMessage.prototype.hasRoomremoveteammessageresponse = function() {
  return jspb.Message.getField(this, 27) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomPingMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomPingMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomPingMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomPingMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    hash: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomPingMessage}
 */
proto.app.gambo.RoomPingMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomPingMessage;
  return proto.app.gambo.RoomPingMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomPingMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomPingMessage}
 */
proto.app.gambo.RoomPingMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomPingMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomPingMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomPingMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomPingMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string hash = 1;
 * @return {string}
 */
proto.app.gambo.RoomPingMessage.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomPingMessage} returns this
 */
proto.app.gambo.RoomPingMessage.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomPongMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomPongMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomPongMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomPongMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    hash: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomPongMessage}
 */
proto.app.gambo.RoomPongMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomPongMessage;
  return proto.app.gambo.RoomPongMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomPongMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomPongMessage}
 */
proto.app.gambo.RoomPongMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomPongMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomPongMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomPongMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomPongMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string hash = 1;
 * @return {string}
 */
proto.app.gambo.RoomPongMessage.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomPongMessage} returns this
 */
proto.app.gambo.RoomPongMessage.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomErrorServerMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomErrorServerMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomErrorServerMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomErrorServerMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    errormessage: jspb.Message.getFieldWithDefault(msg, 1, ""),
    errorcode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomErrorServerMessage}
 */
proto.app.gambo.RoomErrorServerMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomErrorServerMessage;
  return proto.app.gambo.RoomErrorServerMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomErrorServerMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomErrorServerMessage}
 */
proto.app.gambo.RoomErrorServerMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomErrorServerMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomErrorServerMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomErrorServerMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomErrorServerMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getErrorcode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string errorMessage = 1;
 * @return {string}
 */
proto.app.gambo.RoomErrorServerMessage.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomErrorServerMessage} returns this
 */
proto.app.gambo.RoomErrorServerMessage.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string errorCode = 2;
 * @return {string}
 */
proto.app.gambo.RoomErrorServerMessage.prototype.getErrorcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomErrorServerMessage} returns this
 */
proto.app.gambo.RoomErrorServerMessage.prototype.setErrorcode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomErrorClientMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomErrorClientMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomErrorClientMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomErrorClientMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    errormessage: jspb.Message.getFieldWithDefault(msg, 1, ""),
    errorcode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomErrorClientMessage}
 */
proto.app.gambo.RoomErrorClientMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomErrorClientMessage;
  return proto.app.gambo.RoomErrorClientMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomErrorClientMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomErrorClientMessage}
 */
proto.app.gambo.RoomErrorClientMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomErrorClientMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomErrorClientMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomErrorClientMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomErrorClientMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getErrorcode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string errorMessage = 1;
 * @return {string}
 */
proto.app.gambo.RoomErrorClientMessage.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomErrorClientMessage} returns this
 */
proto.app.gambo.RoomErrorClientMessage.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string errorCode = 2;
 * @return {string}
 */
proto.app.gambo.RoomErrorClientMessage.prototype.getErrorcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomErrorClientMessage} returns this
 */
proto.app.gambo.RoomErrorClientMessage.prototype.setErrorcode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.CreateRoomMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.CreateRoomMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CreateRoomMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    privateroom: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    allowsdisconnect: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    minplayers: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxplayers: jspb.Message.getFieldWithDefault(msg, 6, 0),
    additionalgameparameters: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.CreateRoomMessageRequest}
 */
proto.app.gambo.CreateRoomMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.CreateRoomMessageRequest;
  return proto.app.gambo.CreateRoomMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.CreateRoomMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.CreateRoomMessageRequest}
 */
proto.app.gambo.CreateRoomMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGamename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomname(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivateroom(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowsdisconnect(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinplayers(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxplayers(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalgameparameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.CreateRoomMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.CreateRoomMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CreateRoomMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrivateroom();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAllowsdisconnect();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMinplayers();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMaxplayers();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getAdditionalgameparameters();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string gameName = 1;
 * @return {string}
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.getGamename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.CreateRoomMessageRequest} returns this
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.setGamename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomName = 2;
 * @return {string}
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.getRoomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.CreateRoomMessageRequest} returns this
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.setRoomname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool privateRoom = 3;
 * @return {boolean}
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.getPrivateroom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.CreateRoomMessageRequest} returns this
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.setPrivateroom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool allowsDisconnect = 4;
 * @return {boolean}
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.getAllowsdisconnect = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.CreateRoomMessageRequest} returns this
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.setAllowsdisconnect = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 minPlayers = 5;
 * @return {number}
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.getMinplayers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.CreateRoomMessageRequest} returns this
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.setMinplayers = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 maxPlayers = 6;
 * @return {number}
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.getMaxplayers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.CreateRoomMessageRequest} returns this
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.setMaxplayers = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string additionalGameParameters = 7;
 * @return {string}
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.getAdditionalgameparameters = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.CreateRoomMessageRequest} returns this
 */
proto.app.gambo.CreateRoomMessageRequest.prototype.setAdditionalgameparameters = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.CreateRoomMessageResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.CreateRoomMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.CreateRoomMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.CreateRoomMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CreateRoomMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    additionalgameparameters: jspb.Message.getFieldWithDefault(msg, 3, ""),
    additionalroominfo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    proto.app.gambo.TeamInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.CreateRoomMessageResponse}
 */
proto.app.gambo.CreateRoomMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.CreateRoomMessageResponse;
  return proto.app.gambo.CreateRoomMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.CreateRoomMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.CreateRoomMessageResponse}
 */
proto.app.gambo.CreateRoomMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalgameparameters(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalroominfo(value);
      break;
    case 5:
      var value = new proto.app.gambo.TeamInfo;
      reader.readMessage(value,proto.app.gambo.TeamInfo.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.CreateRoomMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.CreateRoomMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.CreateRoomMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.CreateRoomMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdditionalgameparameters();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdditionalroominfo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.app.gambo.TeamInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string roomId = 1;
 * @return {string}
 */
proto.app.gambo.CreateRoomMessageResponse.prototype.getRoomid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.CreateRoomMessageResponse} returns this
 */
proto.app.gambo.CreateRoomMessageResponse.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomName = 2;
 * @return {string}
 */
proto.app.gambo.CreateRoomMessageResponse.prototype.getRoomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.CreateRoomMessageResponse} returns this
 */
proto.app.gambo.CreateRoomMessageResponse.prototype.setRoomname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string additionalGameParameters = 3;
 * @return {string}
 */
proto.app.gambo.CreateRoomMessageResponse.prototype.getAdditionalgameparameters = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.CreateRoomMessageResponse} returns this
 */
proto.app.gambo.CreateRoomMessageResponse.prototype.setAdditionalgameparameters = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string additionalRoomInfo = 4;
 * @return {string}
 */
proto.app.gambo.CreateRoomMessageResponse.prototype.getAdditionalroominfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.CreateRoomMessageResponse} returns this
 */
proto.app.gambo.CreateRoomMessageResponse.prototype.setAdditionalroominfo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated TeamInfo teams = 5;
 * @return {!Array<!proto.app.gambo.TeamInfo>}
 */
proto.app.gambo.CreateRoomMessageResponse.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.app.gambo.TeamInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.TeamInfo, 5));
};


/**
 * @param {!Array<!proto.app.gambo.TeamInfo>} value
 * @return {!proto.app.gambo.CreateRoomMessageResponse} returns this
*/
proto.app.gambo.CreateRoomMessageResponse.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.app.gambo.TeamInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.TeamInfo}
 */
proto.app.gambo.CreateRoomMessageResponse.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.app.gambo.TeamInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.CreateRoomMessageResponse} returns this
 */
proto.app.gambo.CreateRoomMessageResponse.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.JoinRandomRoomMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.JoinRandomRoomMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.JoinRandomRoomMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.JoinRandomRoomMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamename: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.JoinRandomRoomMessageRequest}
 */
proto.app.gambo.JoinRandomRoomMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.JoinRandomRoomMessageRequest;
  return proto.app.gambo.JoinRandomRoomMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.JoinRandomRoomMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.JoinRandomRoomMessageRequest}
 */
proto.app.gambo.JoinRandomRoomMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGamename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.JoinRandomRoomMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.JoinRandomRoomMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.JoinRandomRoomMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.JoinRandomRoomMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string gameName = 1;
 * @return {string}
 */
proto.app.gambo.JoinRandomRoomMessageRequest.prototype.getGamename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRandomRoomMessageRequest} returns this
 */
proto.app.gambo.JoinRandomRoomMessageRequest.prototype.setGamename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.JoinRandomRoomMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.JoinRandomRoomMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.JoinRandomRoomMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameinfo: (f = msg.getGameinfo()) && proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.JoinRandomRoomMessageResponse;
  return proto.app.gambo.JoinRandomRoomMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.JoinRandomRoomMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo;
      reader.readMessage(value,proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.deserializeBinaryFromReader);
      msg.setGameinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.JoinRandomRoomMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.JoinRandomRoomMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.JoinRandomRoomMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    roomname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    privateroom: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    allowsdisconnect: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    minplayers: jspb.Message.getFieldWithDefault(msg, 6, 0),
    maxplayers: jspb.Message.getFieldWithDefault(msg, 7, 0),
    additionalgameparameters: jspb.Message.getFieldWithDefault(msg, 8, ""),
    additionalroominfo: jspb.Message.getFieldWithDefault(msg, 9, ""),
    connectedteamname: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo;
  return proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGamename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomname(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivateroom(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowsdisconnect(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinplayers(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxplayers(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalgameparameters(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalroominfo(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setConnectedteamname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoomname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrivateroom();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAllowsdisconnect();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMinplayers();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMaxplayers();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAdditionalgameparameters();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAdditionalroominfo();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getConnectedteamname();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string gameName = 1;
 * @return {string}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.getGamename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo} returns this
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.setGamename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomId = 2;
 * @return {string}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.getRoomid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo} returns this
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string roomName = 3;
 * @return {string}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.getRoomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo} returns this
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.setRoomname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool privateRoom = 4;
 * @return {boolean}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.getPrivateroom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo} returns this
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.setPrivateroom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool allowsDisconnect = 5;
 * @return {boolean}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.getAllowsdisconnect = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo} returns this
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.setAllowsdisconnect = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 minPlayers = 6;
 * @return {number}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.getMinplayers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo} returns this
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.setMinplayers = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 maxPlayers = 7;
 * @return {number}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.getMaxplayers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo} returns this
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.setMaxplayers = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string additionalGameParameters = 8;
 * @return {string}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.getAdditionalgameparameters = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo} returns this
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.setAdditionalgameparameters = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string additionalRoomInfo = 9;
 * @return {string}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.getAdditionalroominfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo} returns this
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.setAdditionalroominfo = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string connectedTeamName = 10;
 * @return {string}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.getConnectedteamname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo} returns this
 */
proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo.prototype.setConnectedteamname = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional JoinRandomRoomGameInfo gameInfo = 1;
 * @return {?proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.prototype.getGameinfo = function() {
  return /** @type{?proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo} */ (
    jspb.Message.getWrapperField(this, proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo, 1));
};


/**
 * @param {?proto.app.gambo.JoinRandomRoomMessageResponse.JoinRandomRoomGameInfo|undefined} value
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse} returns this
*/
proto.app.gambo.JoinRandomRoomMessageResponse.prototype.setGameinfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.gambo.JoinRandomRoomMessageResponse} returns this
 */
proto.app.gambo.JoinRandomRoomMessageResponse.prototype.clearGameinfo = function() {
  return this.setGameinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.gambo.JoinRandomRoomMessageResponse.prototype.hasGameinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.JoinRoomMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.JoinRoomMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.JoinRoomMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.JoinRoomMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomidorname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pwd: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.JoinRoomMessageRequest}
 */
proto.app.gambo.JoinRoomMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.JoinRoomMessageRequest;
  return proto.app.gambo.JoinRoomMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.JoinRoomMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.JoinRoomMessageRequest}
 */
proto.app.gambo.JoinRoomMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGamename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomidorname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPwd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.JoinRoomMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.JoinRoomMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.JoinRoomMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.JoinRoomMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomidorname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPwd();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string gameName = 1;
 * @return {string}
 */
proto.app.gambo.JoinRoomMessageRequest.prototype.getGamename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRoomMessageRequest} returns this
 */
proto.app.gambo.JoinRoomMessageRequest.prototype.setGamename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomIdOrName = 2;
 * @return {string}
 */
proto.app.gambo.JoinRoomMessageRequest.prototype.getRoomidorname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRoomMessageRequest} returns this
 */
proto.app.gambo.JoinRoomMessageRequest.prototype.setRoomidorname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pwd = 3;
 * @return {string}
 */
proto.app.gambo.JoinRoomMessageRequest.prototype.getPwd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRoomMessageRequest} returns this
 */
proto.app.gambo.JoinRoomMessageRequest.prototype.setPwd = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.JoinRoomMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.JoinRoomMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.JoinRoomMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    roomname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    privateroom: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    allowsdisconnect: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    additionalgameparameters: jspb.Message.getFieldWithDefault(msg, 6, ""),
    additionalroominfo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    connectedteamname: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.JoinRoomMessageResponse}
 */
proto.app.gambo.JoinRoomMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.JoinRoomMessageResponse;
  return proto.app.gambo.JoinRoomMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.JoinRoomMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.JoinRoomMessageResponse}
 */
proto.app.gambo.JoinRoomMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGamename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomname(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivateroom(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowsdisconnect(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalgameparameters(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalroominfo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setConnectedteamname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.JoinRoomMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.JoinRoomMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.JoinRoomMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoomname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrivateroom();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAllowsdisconnect();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAdditionalgameparameters();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAdditionalroominfo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getConnectedteamname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string gameName = 1;
 * @return {string}
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.getGamename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRoomMessageResponse} returns this
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.setGamename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomId = 2;
 * @return {string}
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.getRoomid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRoomMessageResponse} returns this
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string roomName = 3;
 * @return {string}
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.getRoomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRoomMessageResponse} returns this
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.setRoomname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool privateRoom = 4;
 * @return {boolean}
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.getPrivateroom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.JoinRoomMessageResponse} returns this
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.setPrivateroom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool allowsDisconnect = 5;
 * @return {boolean}
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.getAllowsdisconnect = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.JoinRoomMessageResponse} returns this
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.setAllowsdisconnect = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string additionalGameParameters = 6;
 * @return {string}
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.getAdditionalgameparameters = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRoomMessageResponse} returns this
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.setAdditionalgameparameters = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string additionalRoomInfo = 7;
 * @return {string}
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.getAdditionalroominfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRoomMessageResponse} returns this
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.setAdditionalroominfo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string connectedTeamName = 8;
 * @return {string}
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.getConnectedteamname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.JoinRoomMessageResponse} returns this
 */
proto.app.gambo.JoinRoomMessageResponse.prototype.setConnectedteamname = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.TeamInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.TeamInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.TeamInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.TeamInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.TeamInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playersList: jspb.Message.toObjectList(msg.getPlayersList(),
    proto.app.gambo.PlayerInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.TeamInfo}
 */
proto.app.gambo.TeamInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.TeamInfo;
  return proto.app.gambo.TeamInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.TeamInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.TeamInfo}
 */
proto.app.gambo.TeamInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamname(value);
      break;
    case 2:
      var value = new proto.app.gambo.PlayerInfo;
      reader.readMessage(value,proto.app.gambo.PlayerInfo.deserializeBinaryFromReader);
      msg.addPlayers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.TeamInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.TeamInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.TeamInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.TeamInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.app.gambo.PlayerInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string teamName = 1;
 * @return {string}
 */
proto.app.gambo.TeamInfo.prototype.getTeamname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.TeamInfo} returns this
 */
proto.app.gambo.TeamInfo.prototype.setTeamname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated PlayerInfo players = 2;
 * @return {!Array<!proto.app.gambo.PlayerInfo>}
 */
proto.app.gambo.TeamInfo.prototype.getPlayersList = function() {
  return /** @type{!Array<!proto.app.gambo.PlayerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.PlayerInfo, 2));
};


/**
 * @param {!Array<!proto.app.gambo.PlayerInfo>} value
 * @return {!proto.app.gambo.TeamInfo} returns this
*/
proto.app.gambo.TeamInfo.prototype.setPlayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.app.gambo.PlayerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.PlayerInfo}
 */
proto.app.gambo.TeamInfo.prototype.addPlayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.app.gambo.PlayerInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.TeamInfo} returns this
 */
proto.app.gambo.TeamInfo.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.PlayerInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.PlayerInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.PlayerInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.PlayerInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    nickname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    avatarurl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.PlayerInfo}
 */
proto.app.gambo.PlayerInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.PlayerInfo;
  return proto.app.gambo.PlayerInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.PlayerInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.PlayerInfo}
 */
proto.app.gambo.PlayerInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.PlayerInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.PlayerInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.PlayerInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.PlayerInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAvatarurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string nickname = 1;
 * @return {string}
 */
proto.app.gambo.PlayerInfo.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.PlayerInfo} returns this
 */
proto.app.gambo.PlayerInfo.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string avatarUrl = 2;
 * @return {string}
 */
proto.app.gambo.PlayerInfo.prototype.getAvatarurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.PlayerInfo} returns this
 */
proto.app.gambo.PlayerInfo.prototype.setAvatarurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.WaitRoomMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.WaitRoomMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.WaitRoomMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.WaitRoomMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.WaitRoomMessageRequest}
 */
proto.app.gambo.WaitRoomMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.WaitRoomMessageRequest;
  return proto.app.gambo.WaitRoomMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.WaitRoomMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.WaitRoomMessageRequest}
 */
proto.app.gambo.WaitRoomMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGamename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.WaitRoomMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.WaitRoomMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.WaitRoomMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.WaitRoomMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string gameName = 1;
 * @return {string}
 */
proto.app.gambo.WaitRoomMessageRequest.prototype.getGamename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.WaitRoomMessageRequest} returns this
 */
proto.app.gambo.WaitRoomMessageRequest.prototype.setGamename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomId = 2;
 * @return {string}
 */
proto.app.gambo.WaitRoomMessageRequest.prototype.getRoomid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.WaitRoomMessageRequest} returns this
 */
proto.app.gambo.WaitRoomMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.gambo.WaitRoomMessageResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.WaitRoomMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.WaitRoomMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.WaitRoomMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.WaitRoomMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    started: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    connectedplayersList: jspb.Message.toObjectList(msg.getConnectedplayersList(),
    proto.app.gambo.PlayerInfo.toObject, includeInstance),
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    proto.app.gambo.TeamInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.WaitRoomMessageResponse}
 */
proto.app.gambo.WaitRoomMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.WaitRoomMessageResponse;
  return proto.app.gambo.WaitRoomMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.WaitRoomMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.WaitRoomMessageResponse}
 */
proto.app.gambo.WaitRoomMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStarted(value);
      break;
    case 2:
      var value = new proto.app.gambo.PlayerInfo;
      reader.readMessage(value,proto.app.gambo.PlayerInfo.deserializeBinaryFromReader);
      msg.addConnectedplayers(value);
      break;
    case 3:
      var value = new proto.app.gambo.TeamInfo;
      reader.readMessage(value,proto.app.gambo.TeamInfo.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.WaitRoomMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.WaitRoomMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.WaitRoomMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.WaitRoomMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStarted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getConnectedplayersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.app.gambo.PlayerInfo.serializeBinaryToWriter
    );
  }
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.app.gambo.TeamInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool started = 1;
 * @return {boolean}
 */
proto.app.gambo.WaitRoomMessageResponse.prototype.getStarted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.WaitRoomMessageResponse} returns this
 */
proto.app.gambo.WaitRoomMessageResponse.prototype.setStarted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated PlayerInfo connectedPlayers = 2;
 * @return {!Array<!proto.app.gambo.PlayerInfo>}
 */
proto.app.gambo.WaitRoomMessageResponse.prototype.getConnectedplayersList = function() {
  return /** @type{!Array<!proto.app.gambo.PlayerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.PlayerInfo, 2));
};


/**
 * @param {!Array<!proto.app.gambo.PlayerInfo>} value
 * @return {!proto.app.gambo.WaitRoomMessageResponse} returns this
*/
proto.app.gambo.WaitRoomMessageResponse.prototype.setConnectedplayersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.app.gambo.PlayerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.PlayerInfo}
 */
proto.app.gambo.WaitRoomMessageResponse.prototype.addConnectedplayers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.app.gambo.PlayerInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.WaitRoomMessageResponse} returns this
 */
proto.app.gambo.WaitRoomMessageResponse.prototype.clearConnectedplayersList = function() {
  return this.setConnectedplayersList([]);
};


/**
 * repeated TeamInfo teams = 3;
 * @return {!Array<!proto.app.gambo.TeamInfo>}
 */
proto.app.gambo.WaitRoomMessageResponse.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.app.gambo.TeamInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.gambo.TeamInfo, 3));
};


/**
 * @param {!Array<!proto.app.gambo.TeamInfo>} value
 * @return {!proto.app.gambo.WaitRoomMessageResponse} returns this
*/
proto.app.gambo.WaitRoomMessageResponse.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.app.gambo.TeamInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.gambo.TeamInfo}
 */
proto.app.gambo.WaitRoomMessageResponse.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.app.gambo.TeamInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.gambo.WaitRoomMessageResponse} returns this
 */
proto.app.gambo.WaitRoomMessageResponse.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomStateChangeMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomStateChangeMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomStateChangeMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomStateChangeMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomStateChangeMessageRequest}
 */
proto.app.gambo.RoomStateChangeMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomStateChangeMessageRequest;
  return proto.app.gambo.RoomStateChangeMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomStateChangeMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomStateChangeMessageRequest}
 */
proto.app.gambo.RoomStateChangeMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGamename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomStateChangeMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomStateChangeMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomStateChangeMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomStateChangeMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string gameName = 1;
 * @return {string}
 */
proto.app.gambo.RoomStateChangeMessageRequest.prototype.getGamename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomStateChangeMessageRequest} returns this
 */
proto.app.gambo.RoomStateChangeMessageRequest.prototype.setGamename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomId = 2;
 * @return {string}
 */
proto.app.gambo.RoomStateChangeMessageRequest.prototype.getRoomid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomStateChangeMessageRequest} returns this
 */
proto.app.gambo.RoomStateChangeMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomStateChangeMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomStateChangeMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomStateChangeMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomStateChangeMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    changed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomStateChangeMessageResponse}
 */
proto.app.gambo.RoomStateChangeMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomStateChangeMessageResponse;
  return proto.app.gambo.RoomStateChangeMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomStateChangeMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomStateChangeMessageResponse}
 */
proto.app.gambo.RoomStateChangeMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChanged(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomStateChangeMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomStateChangeMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomStateChangeMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomStateChangeMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChanged();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool changed = 1;
 * @return {boolean}
 */
proto.app.gambo.RoomStateChangeMessageResponse.prototype.getChanged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.RoomStateChangeMessageResponse} returns this
 */
proto.app.gambo.RoomStateChangeMessageResponse.prototype.setChanged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomStartGameMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomStartGameMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomStartGameMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomStartGameMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomStartGameMessageRequest}
 */
proto.app.gambo.RoomStartGameMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomStartGameMessageRequest;
  return proto.app.gambo.RoomStartGameMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomStartGameMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomStartGameMessageRequest}
 */
proto.app.gambo.RoomStartGameMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGamename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomStartGameMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomStartGameMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomStartGameMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomStartGameMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string gameName = 1;
 * @return {string}
 */
proto.app.gambo.RoomStartGameMessageRequest.prototype.getGamename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomStartGameMessageRequest} returns this
 */
proto.app.gambo.RoomStartGameMessageRequest.prototype.setGamename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomId = 2;
 * @return {string}
 */
proto.app.gambo.RoomStartGameMessageRequest.prototype.getRoomid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomStartGameMessageRequest} returns this
 */
proto.app.gambo.RoomStartGameMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomStartGameMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomStartGameMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomStartGameMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomStartGameMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomStartGameMessageResponse}
 */
proto.app.gambo.RoomStartGameMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomStartGameMessageResponse;
  return proto.app.gambo.RoomStartGameMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomStartGameMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomStartGameMessageResponse}
 */
proto.app.gambo.RoomStartGameMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomStartGameMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomStartGameMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomStartGameMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomStartGameMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.RoomStartGameMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.RoomStartGameMessageResponse} returns this
 */
proto.app.gambo.RoomStartGameMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomChangeTeamMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomChangeTeamMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomChangeTeamMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomChangeTeamMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    teamname: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomChangeTeamMessageRequest}
 */
proto.app.gambo.RoomChangeTeamMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomChangeTeamMessageRequest;
  return proto.app.gambo.RoomChangeTeamMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomChangeTeamMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomChangeTeamMessageRequest}
 */
proto.app.gambo.RoomChangeTeamMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGamename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomChangeTeamMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomChangeTeamMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomChangeTeamMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomChangeTeamMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTeamname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string gameName = 1;
 * @return {string}
 */
proto.app.gambo.RoomChangeTeamMessageRequest.prototype.getGamename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomChangeTeamMessageRequest} returns this
 */
proto.app.gambo.RoomChangeTeamMessageRequest.prototype.setGamename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomId = 2;
 * @return {string}
 */
proto.app.gambo.RoomChangeTeamMessageRequest.prototype.getRoomid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomChangeTeamMessageRequest} returns this
 */
proto.app.gambo.RoomChangeTeamMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string teamName = 3;
 * @return {string}
 */
proto.app.gambo.RoomChangeTeamMessageRequest.prototype.getTeamname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomChangeTeamMessageRequest} returns this
 */
proto.app.gambo.RoomChangeTeamMessageRequest.prototype.setTeamname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomChangeTeamMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomChangeTeamMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomChangeTeamMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomChangeTeamMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomChangeTeamMessageResponse}
 */
proto.app.gambo.RoomChangeTeamMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomChangeTeamMessageResponse;
  return proto.app.gambo.RoomChangeTeamMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomChangeTeamMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomChangeTeamMessageResponse}
 */
proto.app.gambo.RoomChangeTeamMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomChangeTeamMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomChangeTeamMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomChangeTeamMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomChangeTeamMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.RoomChangeTeamMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.RoomChangeTeamMessageResponse} returns this
 */
proto.app.gambo.RoomChangeTeamMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomChangeTeamNameMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomChangeTeamNameMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomChangeTeamNameMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomChangeTeamNameMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    oldteamname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    newteamname: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomChangeTeamNameMessageRequest}
 */
proto.app.gambo.RoomChangeTeamNameMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomChangeTeamNameMessageRequest;
  return proto.app.gambo.RoomChangeTeamNameMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomChangeTeamNameMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomChangeTeamNameMessageRequest}
 */
proto.app.gambo.RoomChangeTeamNameMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGamename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldteamname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewteamname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomChangeTeamNameMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomChangeTeamNameMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomChangeTeamNameMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomChangeTeamNameMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOldteamname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNewteamname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string gameName = 1;
 * @return {string}
 */
proto.app.gambo.RoomChangeTeamNameMessageRequest.prototype.getGamename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomChangeTeamNameMessageRequest} returns this
 */
proto.app.gambo.RoomChangeTeamNameMessageRequest.prototype.setGamename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomId = 2;
 * @return {string}
 */
proto.app.gambo.RoomChangeTeamNameMessageRequest.prototype.getRoomid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomChangeTeamNameMessageRequest} returns this
 */
proto.app.gambo.RoomChangeTeamNameMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string oldTeamName = 3;
 * @return {string}
 */
proto.app.gambo.RoomChangeTeamNameMessageRequest.prototype.getOldteamname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomChangeTeamNameMessageRequest} returns this
 */
proto.app.gambo.RoomChangeTeamNameMessageRequest.prototype.setOldteamname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string newTeamName = 4;
 * @return {string}
 */
proto.app.gambo.RoomChangeTeamNameMessageRequest.prototype.getNewteamname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomChangeTeamNameMessageRequest} returns this
 */
proto.app.gambo.RoomChangeTeamNameMessageRequest.prototype.setNewteamname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomChangeTeamNameMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomChangeTeamNameMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomChangeTeamNameMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomChangeTeamNameMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomChangeTeamNameMessageResponse}
 */
proto.app.gambo.RoomChangeTeamNameMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomChangeTeamNameMessageResponse;
  return proto.app.gambo.RoomChangeTeamNameMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomChangeTeamNameMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomChangeTeamNameMessageResponse}
 */
proto.app.gambo.RoomChangeTeamNameMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomChangeTeamNameMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomChangeTeamNameMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomChangeTeamNameMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomChangeTeamNameMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.RoomChangeTeamNameMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.RoomChangeTeamNameMessageResponse} returns this
 */
proto.app.gambo.RoomChangeTeamNameMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomAddTeamMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomAddTeamMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomAddTeamMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomAddTeamMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    teamname: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomAddTeamMessageRequest}
 */
proto.app.gambo.RoomAddTeamMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomAddTeamMessageRequest;
  return proto.app.gambo.RoomAddTeamMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomAddTeamMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomAddTeamMessageRequest}
 */
proto.app.gambo.RoomAddTeamMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGamename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomAddTeamMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomAddTeamMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomAddTeamMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomAddTeamMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTeamname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string gameName = 1;
 * @return {string}
 */
proto.app.gambo.RoomAddTeamMessageRequest.prototype.getGamename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomAddTeamMessageRequest} returns this
 */
proto.app.gambo.RoomAddTeamMessageRequest.prototype.setGamename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomId = 2;
 * @return {string}
 */
proto.app.gambo.RoomAddTeamMessageRequest.prototype.getRoomid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomAddTeamMessageRequest} returns this
 */
proto.app.gambo.RoomAddTeamMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string teamName = 3;
 * @return {string}
 */
proto.app.gambo.RoomAddTeamMessageRequest.prototype.getTeamname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomAddTeamMessageRequest} returns this
 */
proto.app.gambo.RoomAddTeamMessageRequest.prototype.setTeamname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomAddTeamMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomAddTeamMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomAddTeamMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomAddTeamMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomAddTeamMessageResponse}
 */
proto.app.gambo.RoomAddTeamMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomAddTeamMessageResponse;
  return proto.app.gambo.RoomAddTeamMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomAddTeamMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomAddTeamMessageResponse}
 */
proto.app.gambo.RoomAddTeamMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomAddTeamMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomAddTeamMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomAddTeamMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomAddTeamMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.RoomAddTeamMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.RoomAddTeamMessageResponse} returns this
 */
proto.app.gambo.RoomAddTeamMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomRemoveTeamMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomRemoveTeamMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomRemoveTeamMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomRemoveTeamMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    teamname: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomRemoveTeamMessageRequest}
 */
proto.app.gambo.RoomRemoveTeamMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomRemoveTeamMessageRequest;
  return proto.app.gambo.RoomRemoveTeamMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomRemoveTeamMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomRemoveTeamMessageRequest}
 */
proto.app.gambo.RoomRemoveTeamMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGamename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomRemoveTeamMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomRemoveTeamMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomRemoveTeamMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomRemoveTeamMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTeamname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string gameName = 1;
 * @return {string}
 */
proto.app.gambo.RoomRemoveTeamMessageRequest.prototype.getGamename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomRemoveTeamMessageRequest} returns this
 */
proto.app.gambo.RoomRemoveTeamMessageRequest.prototype.setGamename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string roomId = 2;
 * @return {string}
 */
proto.app.gambo.RoomRemoveTeamMessageRequest.prototype.getRoomid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomRemoveTeamMessageRequest} returns this
 */
proto.app.gambo.RoomRemoveTeamMessageRequest.prototype.setRoomid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string teamName = 3;
 * @return {string}
 */
proto.app.gambo.RoomRemoveTeamMessageRequest.prototype.getTeamname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.gambo.RoomRemoveTeamMessageRequest} returns this
 */
proto.app.gambo.RoomRemoveTeamMessageRequest.prototype.setTeamname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.gambo.RoomRemoveTeamMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.gambo.RoomRemoveTeamMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.gambo.RoomRemoveTeamMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomRemoveTeamMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.gambo.RoomRemoveTeamMessageResponse}
 */
proto.app.gambo.RoomRemoveTeamMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.gambo.RoomRemoveTeamMessageResponse;
  return proto.app.gambo.RoomRemoveTeamMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.gambo.RoomRemoveTeamMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.gambo.RoomRemoveTeamMessageResponse}
 */
proto.app.gambo.RoomRemoveTeamMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.gambo.RoomRemoveTeamMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.gambo.RoomRemoveTeamMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.gambo.RoomRemoveTeamMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.gambo.RoomRemoveTeamMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.app.gambo.RoomRemoveTeamMessageResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.app.gambo.RoomRemoveTeamMessageResponse} returns this
 */
proto.app.gambo.RoomRemoveTeamMessageResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.app.gambo.RoomMessageType = {
  ROOM_MESSAGE_TYPE_UNKNOWN: 0,
  ROOM_MESSAGE_TYPE_PING: 1,
  ROOM_MESSAGE_TYPE_PONG: 2,
  ROOM_MESSAGE_TYPE_ERROR_SERVER: 3,
  ROOM_MESSAGE_TYPE_ERROR_CLIENT: 4,
  ROOM_MESSAGE_TYPE_CREATE_ROOM_REQUEST: 5,
  ROOM_MESSAGE_TYPE_CREATE_ROOM_RESPONSE: 6,
  ROOM_MESSAGE_TYPE_JOIN_RANDOM_ROOM_REQUEST: 7,
  ROOM_MESSAGE_TYPE_JOIN_RANDOM_ROOM_RESPONSE: 8,
  ROOM_MESSAGE_TYPE_JOIN_ROOM_REQUEST: 9,
  ROOM_MESSAGE_TYPE_JOIN_ROOM_RESPONSE: 10,
  ROOM_MESSAGE_TYPE_WAIT_ROOM_REQUEST: 11,
  ROOM_MESSAGE_TYPE_WAIT_ROOM_RESPONSE: 12,
  ROOM_MESSAGE_TYPE_ROOM_STATE_CHANGE_REQUEST: 13,
  ROOM_MESSAGE_TYPE_ROOM_STATE_CHANGE_RESPONSE: 14,
  ROOM_MESSAGE_TYPE_ROOM_START_GAME_REQUEST: 15,
  ROOM_MESSAGE_TYPE_ROOM_START_GAME_RESPONSE: 16,
  ROOM_MESSAGE_TYPE_ROOM_CHANGE_TEAM_REQUEST: 17,
  ROOM_MESSAGE_TYPE_ROOM_CHANGE_TEAM_RESPONSE: 18,
  ROOM_MESSAGE_TYPE_ROOM_CHANGE_TEAM_NAME_REQUEST: 19,
  ROOM_MESSAGE_TYPE_ROOM_CHANGE_TEAM_NAME_RESPONSE: 20,
  ROOM_MESSAGE_TYPE_ROOM_ADD_TEAM_REQUEST: 21,
  ROOM_MESSAGE_TYPE_ROOM_ADD_TEAM_RESPONSE: 22,
  ROOM_MESSAGE_TYPE_ROOM_REMOVE_TEAM_REQUEST: 23,
  ROOM_MESSAGE_TYPE_ROOM_REMOVE_TEAM_RESPONSE: 24
};

goog.object.extend(exports, proto.app.gambo);
