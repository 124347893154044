<app-user-header *ngIf="!quizRunnerSocket.isMobile"
                 [numberOfQuestions]="quizTotalQuestionsNumber"
                 [questionNumber]="questionNumber()">
</app-user-header>
<app-user-header *ngIf="quizRunnerSocket.isMobile"></app-user-header>

<app-quiz-timer *ngIf="quizQuestion?.getAnswertype() !== QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_HOSTED"></app-quiz-timer>

<div class="quiz-question-content">
    <div class="left-question-tail"></div>
    <div class="quiz-question-wrapper">
        <!--<div *ngIf="!showResults"
             class="question-answer-wrapper">-->
        <!--<div *ngIf="!gameFinished && questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED">
            The game will start soon
        </div>-->
        <div *ngIf="quizQuestion"
             [ngClass]="quizRunnerSocket.isMobile ? 'question-card-mobile' : 'question-card'">
            <app-quiz-runner-question [ngClass]="{'without-answers':
                                      quizQuestion?.getAnswertype() !== QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_ONE
                                      && quizQuestion?.getAnswertype() !== QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_MULTI}">
            </app-quiz-runner-question>
            <!--<app-quiz-runner-answer *ngIf="quizQuestion?.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_ONE
                                    || quizQuestion?.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_MULTI">
            </app-quiz-runner-answer>-->
            <app-quiz-runner-answer *ngIf="quizRunnerSocket.isPlayer
                                    || quizQuestion?.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_ONE
                                    || quizQuestion?.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_MULTI">
            </app-quiz-runner-answer>
        </div>
        <div *ngIf="!quizQuestion"
             class="empty-question-wrapper">
            <div class="empty-question">
                <mat-icon class="mat-icon-rounded">alarm</mat-icon>
                <div>Please wait, the game will start soon</div>
            </div>
        </div>
        <!-- First answered player and accepting/rejecting buttons -->
        <div *ngIf="quizRunnerSocket.isHost && questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_BUTTON_BLOCKED"
             class="first-answered-player-wrapper">
            <button mat-fab
                    color="primary"
                    (click)="hostVerificationClickHandler(true)">
                <mat-icon class="mat-icon-rounded">check</mat-icon>
            </button>
            <div *ngIf="firstAnsweredPlayer"
                 class="player-information">
                <img [src]="firstAnsweredPlayer.getAvatarurl()"
                     class="avatar-image">
                <div [innerHTML]="firstAnsweredPlayer.getNickname()"></div>
            </div>
            <button mat-fab
                    (click)="hostVerificationClickHandler(false)">
                <mat-icon class="mat-icon-rounded">close</mat-icon>
            </button>
        </div>
        <div *ngIf="quizRunnerSocket.isHost"
             class="host-control-panel">
            <div *ngIf="!gameFinished && questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED"
                 (click)="quizRunnerSocket.startQuestionRequest()"
                 class="control-button">
                Start question
                <mat-icon class="mat-icon-rounded">chevron_right</mat-icon>
            </div>
            <div *ngIf="!gameFinished
                        && questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_STARTED
                        || questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_BUTTON_BLOCKED"
                 (click)="quizRunnerSocket.finishQuestionRequest()"
                 class="control-button">
                Finish question
                <mat-icon class="mat-icon-rounded">sports_score</mat-icon>
            </div>
            <div *ngIf="gameFinished"
                 (click)="openMenu()"
                 class="control-button">
                Quit
                <mat-icon class="mat-icon-rounded">transit_enterexit</mat-icon>
            </div>
            <div *ngIf="gameFinished"
                 (click)="showResults = true"
                 class="control-button">
                Results
                <mat-icon class="mat-icon-rounded">transit_enterexit</mat-icon>
            </div>
        </div>
        <!--</div>-->
    </div>
    <div class="right-question-tail"></div>
</div>
<app-quiz-runner-players *ngIf="true"></app-quiz-runner-players>
<app-progress *ngIf="quizRunnerSocket.isMobile"
              [mini]="true"
              [numberOfQuestions]="quizTotalQuestionsNumber"
              [questionNumber]="questionNumber()">
</app-progress>

<!--<div *ngIf="!quizRunnerSocket.isMobile"
     class="quiz-question-content">
    <div class="left-question-tail"></div>
    <div class="quiz-question-wrapper">
        &lt;!&ndash;<div *ngIf="!showResults"
             class="question-answer-wrapper">&ndash;&gt;
        &lt;!&ndash;<div *ngIf="!gameFinished && questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED">
            The game will start soon
        </div>&ndash;&gt;
        <div class="question-card">
            <app-quiz-runner-question [ngClass]="{'without-answers':
                                      quizQuestion?.getAnswertype() !== QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_ONE
                                      && quizQuestion?.getAnswertype() !== QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_MULTI}">
            </app-quiz-runner-question>
            <app-quiz-runner-answer *ngIf="quizQuestion?.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_ONE
                                    || quizQuestion?.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_MULTI">
            </app-quiz-runner-answer>
        </div>
        &lt;!&ndash; First answered player and accepting/rejecting buttons &ndash;&gt;
        <div *ngIf="quizRunnerSocket.isHost && questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_BUTTON_BLOCKED"
             class="first-answered-player-wrapper">
            <button mat-fab
                    color="primary"
                    (click)="hostVerificationClickHandler(true)">
                <mat-icon class="mat-icon-rounded">check</mat-icon>
            </button>
            <div *ngIf="firstAnsweredPlayer"
                 class="player-information">
                <img [src]="firstAnsweredPlayer.getAvatarurl()"
                     class="avatar-image">
                <div [innerHTML]="firstAnsweredPlayer.getNickname()"></div>
            </div>
            <button mat-fab
                    (click)="hostVerificationClickHandler(false)">
                <mat-icon class="mat-icon-rounded">close</mat-icon>
            </button>
        </div>
        <div *ngIf="quizRunnerSocket.isHost"
             class="host-control-panel">
            <div *ngIf="!gameFinished && questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED"
                 (click)="quizRunnerSocket.startQuestionRequest()"
                 class="control-button">
                Start question
                <mat-icon class="mat-icon-rounded">chevron_right</mat-icon>
            </div>
            <div *ngIf="!gameFinished
                        && questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_STARTED
                        || questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_BUTTON_BLOCKED"
                 (click)="quizRunnerSocket.finishQuestionRequest()"
                 class="control-button">
                Finish question
                <mat-icon class="mat-icon-rounded">sports_score</mat-icon>
            </div>
            <div *ngIf="gameFinished"
                 (click)="openMenu()"
                 class="control-button">
                Quit
                <mat-icon class="mat-icon-rounded">transit_enterexit</mat-icon>
            </div>
            <div *ngIf="gameFinished"
                 (click)="showResults = true"
                 class="control-button">
                Results
                <mat-icon class="mat-icon-rounded">transit_enterexit</mat-icon>
            </div>
        </div>
        &lt;!&ndash;</div>&ndash;&gt;
    </div>
    <div class="right-question-tail"></div>
</div>
<div *ngIf="quizRunnerSocket.isMobile"
     class="quiz-question-content">
    <div class="left-question-tail-user"></div>
    <div class="quiz-question-wrapper">
        &lt;!&ndash;<div *ngIf="!showResults"
             class="question-answer-wrapper">&ndash;&gt;
        &lt;!&ndash;<div *ngIf="!gameFinished && questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED">
            The game will start soon
        </div>&ndash;&gt;
        <div class="question-card-user">
            <app-quiz-runner-question [ngClass]="{'without-answers':
            quizQuestion?.getAnswertype() !== QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_ONE
            && quizQuestion?.getAnswertype() !== QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_MULTI
            }">
            </app-quiz-runner-question>
            <app-quiz-runner-answer></app-quiz-runner-answer>
        </div>
        &lt;!&ndash;</div>&ndash;&gt;
    </div>
    <div class="right-question-tail-user"></div>
</div>-->
<!--
<div class="quiz-runner-wrapper"
     *ngIf="quizRunnerSocket.isHost">
    <app-user-header></app-user-header>
    &lt;!&ndash;<div class="header">
        <div class="logo-wrapper">
            <img src="assets/images/LogoFill.png"
                 alt="Gambo"
                 class="logo">
        </div>
        <app-quiz-timer *ngIf="quizQuestion?.getAnswertype() !== QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_HOSTED"
                        class="host-quiz-timer">
        </app-quiz-timer>
        <div *ngIf="quizName" class="quiz-name">{{ quizName }}</div>
    </div>&ndash;&gt;
    <div class="content-wrapper">
        <app-quiz-runner-players></app-quiz-runner-players>
&lt;!&ndash;        <app-quiz-runner-scoreboard></app-quiz-runner-scoreboard>&ndash;&gt;
        <div class="right-content-wrapper">
            <div *ngIf="!showResults"
                 class="question-answer-wrapper">
                <app-quiz-runner-question></app-quiz-runner-question>
                <app-quiz-runner-answer></app-quiz-runner-answer>
            </div>
            <app-quiz-runner-scoreboard *ngIf="showResults"></app-quiz-runner-scoreboard>
            <div class="host-control-panel">
                <div *ngIf="!gameFinished && questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED"
                     class="control-button">
                    <button mat-raised-button
                            color="primary"
                            (click)="quizRunnerSocket.startQuestionRequest()">
                        <mat-icon>flag</mat-icon>
                        Start question
                    </button>
                </div>
                <div *ngIf="!gameFinished && questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_STARTED"
                     class="control-button">
                    <button mat-raised-button
                            color="primary"
                            (click)="quizRunnerSocket.finishQuestionRequest()">
                        <mat-icon>sports_score</mat-icon>
                        Finish question
                    </button>
                </div>
                <div *ngIf="gameFinished"
                     class="control-button">
                    <button mat-raised-button
                            color="primary"
                            (click)="openMenu()">
                        <mat-icon>transit_enterexit</mat-icon>
                        Quit
                    </button>
                </div>
                <div *ngIf="gameFinished"
                     class="control-button">
                    <button mat-raised-button
                            color="primary"
                            (click)="showResults = true">
                        <mat-icon>transit_enterexit</mat-icon>
                        Results
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="quiz-runner-wrapper"
     *ngIf="!quizRunnerSocket.isHost">
    <div class="header">
        <img src="assets/images/LogoFill.png"
             alt="Gambo"
             class="logo">
        <ng-container *ngIf="quizQuestion?.getAnswertype() !== QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_HOSTED">
            <app-quiz-timer></app-quiz-timer>
        </ng-container>
    </div>
    <div class="content-wrapper">
        <div class="right-content-wrapper">
            <div class="question-answer-wrapper">
                <div class="player-status-bar">
                    <div *ngIf="quizQuestion"
                         class="block-wrapper neumorphic-pressed">
                        <div class="block">
                            <div>Question</div>
                            <div>{{ quizQuestion.getNumber() }}</div>
                        </div>
                    </div>
                    <div *ngIf="!quizRunnerSocket.isHost"
                         class="block-wrapper neumorphic-pressed">
                        <div class="block">
                            <div>Score</div>
                            <div>{{ playerScore.getTotalscore() }}</div>
                        </div>
                    </div>
                </div>
                <app-quiz-runner-question></app-quiz-runner-question>
                <app-quiz-runner-answer></app-quiz-runner-answer>
            </div>
        </div>
    </div>
    <br/>
    <div class="host-control-panel">
        <div *ngIf="gameFinished"
             class="control-button">
            <button mat-raised-button
                    color="primary"
                    (click)="openMenu()">
                <mat-icon>transit_enterexit</mat-icon>
                Quit
            </button>
        </div>
    </div>
</div>
-->
