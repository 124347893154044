<app-user-header></app-user-header>
<div class="room-manager-wrapper">
    <div class="quiz-info-wrapper">
        <div class="questions-number">
            {{ quizType === 'AI_TEXT' ? '2 вопроса' : '' }}
        </div>
        <div class="quiz-name">
            {{ quizType === 'AI_TEXT' ? 'AI quiz' : '' }}
        </div>
        <div class="quiz-description">
            {{ quizType === 'AI_TEXT' ? 'AI quiz — это квиз с уникальными вопросами, генерируемый с помощью нейросети.' : '' }}
        </div>
    </div>
    <app-room-manager-user-list [players]="players"></app-room-manager-user-list>
    <div *ngIf="roomId.length > 0">
        <qrcode [qrdata]="'https://ai-partygames.com/room-manager?action=join&id=' + roomId"
                [width]="256"
                [errorCorrectionLevel]="'M'">
        </qrcode>
    </div>
</div>
<button mat-button
        *ngIf="isHost"
        [disabled]="players.length < 2"
        [matTooltip]="'QUIZ.AI_QUIZ.START_GAME_TOOLTIP'"
        (click)="startButtonHandler()"
        class="start-game-button">
    <mat-icon class="mat-icon-rounded">play_circle</mat-icon>
    Start Game
</button>
<!--<div class="room-manager-wrapper">
    <div *ngIf="roomId.length > 0">
        <qrcode [qrdata]="'https://ai-partygames.com/room-manager?action=join&id=' + roomId"
                [width]="256"
                [errorCorrectionLevel]="'M'">
        </qrcode>
    </div>
    <div *ngIf="players">
        <mat-card *ngFor="let player of players"
                  class="user-card">
            <div class="player-information">
                <img [src]="player.getAvatarurl()"
                     class="avatar-image">
                <div>{{ player.getNickname() }}</div>
            </div>
            &lt;!&ndash;<mat-card-header>
                <mat-card-title>{{ player.getNickname() }}</mat-card-title>
            </mat-card-header>&ndash;&gt;
    &lt;!&ndash;        <mat-card-subtitle>{{ player.get }}</mat-card-subtitle>&ndash;&gt;
    &lt;!&ndash;        <mat-card-content>{{ player.description }}</mat-card-content>&ndash;&gt;
        </mat-card>
    </div>
    <button mat-raised-button
            color="primary"
            *ngIf="isHost"
            (click)="startButtonHandler()">
        START
    </button>
</div>-->
