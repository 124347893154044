<div class="dialog-wrapper">
    <div mat-dialog-content
         class="dialog-content-wrapper">
        <div class="header">{{ 'QUIZ.AI_QUIZ.TOPIC' | translate }}</div>
        <div *ngIf="this.topics.length === 0"
             class="spinner-wrapper">
            <mat-spinner></mat-spinner>
        </div>
        <mat-form-field *ngIf="this.topics.length > 0">
            <mat-label>Select topic</mat-label>
            <mat-select [value]="this.topics[0]"
                        (valueChange)="changeTopicHandler($event)">
                <mat-option *ngFor="let topic of topics"
                            [value]="topic">
                    {{topic}}
                </mat-option>
                <mat-option [value]="'custom'">Ввести свою тему</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="selectedTopic === 'custom'"
                        appearance="fill"
                        class="dialog-input">
            <mat-label>{{ 'QUIZ.AI_QUIZ.CUSTOM_TOPIC' | translate }}</mat-label>
            <input matInput
                   minlength="1"
                   maxlength="64"
                   [value]="customTopic"
                   (input)="changeCustomTopicHandler($event.target)">
        </mat-form-field>
    </div>
    <div *ngIf="quizTopic !== 'Общая'"
         class="premium-tooltip">
        <span>Для генерации квиза на любую тему приобретите премиум подписку</span>
        <!--<button mat-raised-button
                color="primary"
                [disabled]="true"
                cdkFocusInitial
                style="margin-top: 10px">
            Оформить подписку временно невозможно
        </button>-->
        <form class="payform-tinkoff" name="payform-tinkoff" onsubmit="pay(this); return false;">
            <input class="payform-tinkoff-row" type="hidden" name="terminalkey" value="1717520094789DEMO">
            <input class="payform-tinkoff-row" type="hidden" name="frame" value="false">
            <input class="payform-tinkoff-row" type="hidden" name="language" value="ru">
            <input class="payform-tinkoff-row" type="hidden" name="receipt" value="{{receipt}}">
            <input class="payform-tinkoff-row" type="hidden" placeholder="Сумма заказа" name="amount" value="100" required>
            <input class="payform-tinkoff-row" type="hidden" placeholder="Номер заказа" name="order">
            <input class="payform-tinkoff-row" type="hidden" placeholder="Описание заказа" name="description" value="Премиум подписка на месяц">
            <input class="payform-tinkoff-row" type="hidden" placeholder="ФИО плательщика" name="name">
            <input class="payform-tinkoff-row" type="email" placeholder="E-mail" name="email" required>
            <input class="payform-tinkoff-row" type="hidden" placeholder="Контактный телефон" name="phone">
            <input class="payform-tinkoff-row payform-tinkoff-btn" type="submit" value="Оплатить">
        </form>
    </div>
    <button mat-raised-button
            color="primary"
            [disabled]="quizTopic === '' || quizTopic !== 'Общая'"
            (click)="createGame()"
            cdkFocusInitial>
        {{ 'COMMON.CREATE' | translate }}
    </button>
</div>
