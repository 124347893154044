import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BetaAccessLandingComponent } from '../modules/beta-access-landing/beta-access-landing/beta-access-landing.component';
import { PrivacyPolicyQuizComponent } from '../modules/privacy-policy-quiz/privacy-policy-quiz/privacy-policy-quiz.component';
import { NewsletterUnsubscribeComponent } from '../modules/newsletter-unsubscribe/newsletter-unsubscribe/newsletter-unsubscribe.component';
import { AuthComponent } from '../modules/auth/auth/auth.component';
import { AuthGuard } from '../modules/auth/auth/auth.guard';
import { GamesComponent } from '../modules/games/games/games.component';
import { QuizComponent } from '../modules/quiz-constructor/quiz/quiz.component';
import { QuestionGenerationComponent } from '../modules/quiz-constructor/question-generation/question-generation/question-generation.component';
import { QuizListComponent } from '../modules/quiz-constructor/quiz-list/quiz-list.component';
import { QuizEditorComponent } from '../modules/quiz-constructor/quiz-editor/quiz-editor.component';
import { RoomManagerComponent } from '../modules/room-manager/room-manager/room-manager.component';
import { QuizRunnerComponent } from '../modules/quiz-runner/quiz-runner/quiz-runner.component';
import { AdminQuizListComponent } from '../modules/quiz-constructor/quiz-list/admin-quiz-list/admin-quiz-list.component';
import { AdminGuard } from '../modules/auth/auth/admin.guard';
import { QuizPromoComponent } from '../modules/quiz-promo/quiz-promo.component';
import { AiPartygamesComponent } from '../modules/ai-partygames/ai-partygames.component';
import { QuizzesComponent } from '../modules/quizzes/quizzes.component';
import { AiQuizComponent } from '../modules/ai-partygames/ai-quiz/ai-quiz.component';

const routes: Routes = [
    {
        path: '',
        component: AiPartygamesComponent
    },
    {
        path: 'ai-quiz',
        component: AiQuizComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'board-games',
        component: BetaAccessLandingComponent
    },
    {
        path: 'alex',
        component: QuizPromoComponent
    },
    {
        path: 'quizzes',
        component: QuizzesComponent
    },
    {
        path: 'board-games',
        component: BetaAccessLandingComponent
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyQuizComponent
    },
    {
        path: 'unsubscribe',
        component: NewsletterUnsubscribeComponent
    },
    {
        path: 'auth',
        component: AuthComponent
    },
    {
        path: 'quiz',
        component: QuizComponent
    },
    {
        path: 'quiz-list',
        component: QuizListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'moderation',
        component: AdminQuizListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'quiz-editor',
        component: QuizEditorComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'question-generation',
        component: QuestionGenerationComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'games',
        component: GamesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'room-manager',
        component: RoomManagerComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'quiz-runner',
        component: QuizRunnerComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            {
                enableTracing: false,
                scrollPositionRestoration: 'enabled'
            }
        )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
