import { Component, OnInit } from '@angular/core';
import { RoomManagerService } from '../room-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { PlayerInfo } from '../../../../proto/generated/room_pb';
import { getDownloadURL, getMetadata, getStorage, ref } from '@angular/fire/storage';
import { getAuth } from 'firebase/auth';

@Component({
    selector: 'app-room-manager',
    templateUrl: './room-manager.component.html',
    styleUrls: ['./room-manager.component.scss'],
    providers: [RoomManagerService]
})
export class RoomManagerComponent implements OnInit {

    public isHost = false;
    public action = '';
    public roomId = '';
    public players = new Array<PlayerInfo>();
    public quizType: 'AI_TEXT' | 'SONG' | 'EMPTY' = 'EMPTY';

    constructor(
        private roomManagerService: RoomManagerService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this.quizType = localStorage.getItem('quizType') as 'AI_TEXT' | 'SONG' | 'EMPTY';
        this.roomManagerService.waitRoomMessageResponseSubject.subscribe(roomState => {
            console.log('Got new room state');
            if (roomState.getStarted()) {
                this.router.navigate(['quiz-runner']);
            } else {
                this.players = new Array(...roomState.getConnectedplayersList());
            }
        });
        this.roomManagerService.roomStartGameMessageResponseSubject
            .pipe(first())
            .subscribe(roomStartGameMessageResponse => {
                if (roomStartGameMessageResponse.getResult()) {
                    this.router.navigate(['quiz-runner']);
                }
            });
        this.activatedRoute.queryParams.subscribe(params => {
            const action: 'run' | 'join' = params.action;
            if (action === 'run') {
                this.isHost = true;
                localStorage.setItem('isHost', 'true');
                if (localStorage.getItem('roomId')) {
                    this.roomId = localStorage.getItem('roomId');
                    this.roomManagerService.roomStatusRequest(this.roomId);
                } else {
                    // Subscribe to get response message once on create
                    this.roomManagerService.createRoomMessageResponseSubject
                        .pipe(first())
                        .subscribe(createRoomMessageResponse => {
                            const roomId = createRoomMessageResponse.getRoomid().toString();
                            localStorage.setItem('roomId', roomId);
                            this.roomId = roomId;
                        });
                    const quizId: string = localStorage.getItem('quizId');
                    // console.log(`Run game ${params.game} with id ${quizId}`);
                    if (quizId === 'deadbeef-dead-beef-dead-beeeeeeeeeef' && this.quizType === 'AI_TEXT') {
                        this.roomManagerService.createRoom(quizId, 'AI_TEXT', localStorage.getItem('quizTopic'));
                    } else {
                        this.roomManagerService.createRoom(quizId, 'EMPTY');
                    }
                }
            } else if (action === 'join') {
                localStorage.setItem('isHost', 'false');
                this.roomId = params.id || localStorage.getItem('roomId');
                if (localStorage.getItem('roomId') && localStorage.getItem('roomId') === this.roomId) {
                    this.roomManagerService.roomStatusRequest(this.roomId);
                } else {
                    // Subscribe to get response message once on join
                    this.roomManagerService.joinRoomMessageResponseSubject
                        .pipe(first())
                        .subscribe(joinRoomMessageResponse => {
                            const roomId = joinRoomMessageResponse.getRoomid().toString();
                            localStorage.setItem('roomId', roomId);
                            this.roomId = roomId;
                            this.roomManagerService.roomStatusRequest(roomId);
                        });
                    this.roomManagerService.joinRoom(this.roomId);
                }
            }
        });
    }

    startButtonHandler() {
        this.roomManagerService.startRequest(this.roomId);
    }
}
