import { GameUserInfo, QuizMoveResponseType, QuizQuestion, QuizQuestionStatus, QuizScoreData } from '../../../../proto/generated/gambo_pb';
import { QuizRunnerActions, QuizRunnerActionType } from './quiz-runner.actions';

export interface QuizRunnerState {
    quizName: string;
    quizQuestion: QuizQuestion;
    questionStatus: number;
    remainingTime: number;
    answeredPlayersList: Array<number>;
    gameFinished: boolean;
    elapsedTime: number;
    playerAnswer: string;
    playerCount: number;
    playerElapsedTime: number;
    playersList: Array<GameUserInfo>;
    playerNumber: number;
    scoreboardList: Array<QuizScoreData>;
    totalQuestions: number;
    responseType: number;
    answeredPlayerNumber: number;
    questionFileSrc: Blob;
}

export const initialState: QuizRunnerState = {
    quizName: null,
    quizQuestion: null,
    questionStatus: null,
    remainingTime: null,
    answeredPlayersList: new Array<number>(),
    gameFinished: null,
    elapsedTime: null,
    playerAnswer: null,
    playerCount: null,
    playerElapsedTime: null,
    playersList: new Array<GameUserInfo>(),
    playerNumber: null,
    scoreboardList: new Array<QuizScoreData>(),
    totalQuestions: null,
    responseType: null,
    answeredPlayerNumber: null,
    questionFileSrc: null,
};

export function quizRunnerReducer(state = initialState, action: QuizRunnerActions): QuizRunnerState {
    switch (action.type) {
        case QuizRunnerActionType.SetCurrentStateResponse:
            console.log('DEBUG: GOT NEW SCORES STATE', action.quizCurrentStateMessageResponse.getScoreboardList());
            console.log('HERERE');
            console.log(action.quizCurrentStateMessageResponse);
            return {
                ...state,
                quizName: action.quizCurrentStateMessageResponse.getQuizname(),
                quizQuestion: action.quizCurrentStateMessageResponse.getQuestion(),
                questionStatus: action.quizCurrentStateMessageResponse.getQuestionstatus(),
                remainingTime: action.quizCurrentStateMessageResponse.getRemainingtime(),
                answeredPlayersList: action.quizCurrentStateMessageResponse.getAnsweredplayersList(),
                gameFinished: action.quizCurrentStateMessageResponse.getGamefinished(),
                playerAnswer: action.quizCurrentStateMessageResponse.getPlayeranswer(),
                playerCount: action.quizCurrentStateMessageResponse.getPlayercount(),
                playerElapsedTime: action.quizCurrentStateMessageResponse.getPlayerelapsedtime(),
                playersList: action.quizCurrentStateMessageResponse.getPlayersList(),
                playerNumber: action.quizCurrentStateMessageResponse.getPlayernumber(),
                scoreboardList: action.quizCurrentStateMessageResponse.getScoreboardList(),
                totalQuestions: action.quizCurrentStateMessageResponse.getTotalquestions()
            };
        case QuizRunnerActionType.SetQuizMoveResponse:
            // Define question status by move type
            const responseType = action.quizMoveMessageResponse.getResponsetype();
            let questionStatus: number = QuizQuestionStatus.QUIZ_QUESTION_STATUS_UNKNOWN;
            if (
                responseType === QuizMoveResponseType.QUIZ_MOVE_RESPONSE_TYPE_STARTED_QUESTION
                || responseType === QuizMoveResponseType.QUIZ_MOVE_RESPONSE_TYPE_FINISHED_QUESTION_USER
                || responseType === QuizMoveResponseType.QUIZ_MOVE_RESPONSE_TYPE_BUTTON_UNBLOCKED
            ) {
                questionStatus = QuizQuestionStatus.QUIZ_QUESTION_STATUS_STARTED;
            } else if (responseType === QuizMoveResponseType.QUIZ_MOVE_RESPONSE_TYPE_FINISHED_QUESTION_HOST) {
                questionStatus = QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED;
            } else if (responseType === QuizMoveResponseType.QUIZ_MOVE_RESPONSE_TYPE_BUTTON_BLOCKED) {
                questionStatus = QuizQuestionStatus.QUIZ_QUESTION_STATUS_BUTTON_BLOCKED;
            }

            let scoreboardList = state.scoreboardList;
            if (responseType === QuizMoveResponseType.QUIZ_MOVE_RESPONSE_TYPE_FINISHED_QUESTION_HOST) {
                console.log('DEBUG: GOT NEW SCORES MOVE', action.quizMoveMessageResponse.getScoreboardList());
                scoreboardList = action.quizMoveMessageResponse.getScoreboardList();
            }

            return {
                ...state,
                responseType,
                questionStatus,
                scoreboardList,
                quizQuestion: action.quizMoveMessageResponse.getQuestion(),
                playerAnswer: action.quizMoveMessageResponse.getPlayeranswer(),
                remainingTime: action.quizMoveMessageResponse.getQuestion().getTimeout(),
                answeredPlayersList: action.quizMoveMessageResponse.getAnsweredplayersList(),
                gameFinished: action.quizMoveMessageResponse.getGamefinished()
            };
        case QuizRunnerActionType.SetQuestion:
            return {
                ...state,
                quizQuestion: action.quizQuestion
            };
        case QuizRunnerActionType.SetQuestionFileSrc:
            return {
                ...state,
                questionFileSrc: action.questionFileBlob
            };
        default:
            return state;
    }
}
